function createConfigFromEnv() {
    const config = {
        oauthEnabled: Boolean(process.env.REACT_APP_OAUTH_ENABLED),
        limitAssessmentAttempts: Boolean(process.env.REACT_APP_LIMIT_ASSESSMENT_ATTEMPTS),
        appleClientId: process.env.REACT_APP_APPLE_CLIENT_ID,
    }
    return config
}

export default createConfigFromEnv()
