"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.createTwirpRequest = exports.throwTwirpError = exports.TwirpError = void 0;
var TwirpError = /** @class */ (function (_super) {
    __extends(TwirpError, _super);
    function TwirpError(te) {
        var _this = _super.call(this, te.msg) || this;
        _this.code = te.code;
        _this.meta = te.meta;
        return _this;
    }
    return TwirpError;
}(Error));
exports.TwirpError = TwirpError;
var throwTwirpError = function (resp) {
    return resp.json().then(function (err) { throw new TwirpError(err); });
};
exports.throwTwirpError = throwTwirpError;
var createTwirpRequest = function (url, body, headersOverride) {
    if (headersOverride === void 0) { headersOverride = {}; }
    var headers = __assign({
        "Content-Type": "application/json"
    }, headersOverride);
    return new Request(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
    });
};
exports.createTwirpRequest = createTwirpRequest;
