import { useState, useContext, useEffect, Fragment } from "react"
import { questions } from "../spec/utils"
import icons from "../icn/index"
import "../css/Summary.css"
import ModalContext from "../modal"
import { AssessmentButton } from "../components/AssessmentComponents"
import { useAudit } from "../audit"

export function index(spec, id) {
  for (let i = 0; i < spec.children.length; i++) {
    const s = spec.children[i]
    const found = questions(s).find((q) => q.id === id)
    if (found) {
      return i
    }
  }
  return -1
}

const summaryQuestion = {
  buyingForSelf: "Purchasing for yourself",
  difficulties: "Recurring difficulties getting or keeping an erection hard enough for sex",
  otherEDMedication: "Medicine for erectile dysfunction",
  sexAtBirth: "Sex at Birth",
  birthDate: "Date of Birth",
  nitrateQuestion: "Ever prescribed a nitrate medicine",
  popperQuestion: "Take “poppers”",
  hypertension: "Medicine for pulmonary hypertension",
  heartNotSafeForSex: "Not safe for you to have sex",
  heartFailure: "Heart failure",
  heart_attack: "Heart attack",
  stroke: "Stroke",
  heart_surgery_or_procedure: "Heart surgery",
  breathlessness_with_activity: "Breathlessness with light or moderate activity",
  dizziness_fainting: "Get dizzy or faint when you stand up",
  unexplained_indigestion: "Unexplained indigestion",
  angina_or_chest_pain: "Angina or chest pain",
  irregular_heartbeat: "Irregular heartbeat",
  heart_valve_issue: "Heart valve issues",
  uncontrolled_high_blood_pressure: "Uncontrolled high blood pressure",
  low_blood_pressure: "Low blood pressure",
  sudden_loss_of_vision: "Sudden loss of vision",
  sudden_loss_of_hearing: "Sudden loss of hearing",
  sickle_cell_anemia: "Sickle cell anemia",
  multiple_myeloma: "Multiple myeloma",
  leukemia: "Leukemia",
  bleeding_problems: "Bleeding problems",
  stomach_ulcers: "Stomach ulcers",
  highBloodPressure: "Medicine for high blood pressure",
  antifungalMedication: "Itraconazole or ketoconazole (antifungals)",
  antibiotics: "Clarithromycin or telithromycin (antibiotics)",
  antidepressants: "Nefazodone (antidepressant)",
  hivMedication: "HIV medicine",
  severe_kidney_disease: "Severe kidney disease",
  severe_liver_disease: "Severe liver disease",
  retinitis_pigmentosa: "Retinitis pigmentosa",
  nonCardiacHealthConditions: "Non-Cardiac Health Conditions",
  avanafilAllergy: "Allergic reaction to avanafil",
  erectionLongerThanFourHours: "Erection lasted longer than 4 hours",
  adbuPeyronies: "Deformed penis shape",
  enlargedProstate: "Medicine for an enlarged prostate (BPH)",
}

// const excludeIf = {
//   //depression: "diagnosedDepression",
// }

const editOverride = {
  //diagnosedDepression: "depression",
}

export function displayList(answer, options = []) {
  if (answer.length === 0) {
    return "None of these"
  }

  const text = {}
  for (const opt of options) {
    text[opt.value] = opt.title
  }

  return answer.map((val) => text[val] || val).join(", ")
}

const formatting = {
  no: "No",
  yes: "Yes",
}

export function ConfirmModal({ onGoBack, onConfirm }) {
  useAudit({ type: "CONFIRM_ANSWERS_CHECKED" })

  return (
    <div className="summary-modal">
      <h3>I confirm that the information I have provided is truthful and accurate to the best of my knowledge.</h3>
      <div>
        <div style={{ paddingTop: "16px" }}>
          <AssessmentButton variant="secondary" onClick={onConfirm}>
            I Confirm
          </AssessmentButton>
        </div>
      </div>
    </div>
  )
}

// This gives individual options for checkbox lists their own line in the summary
export function makeFoldQuestions({ answers, questions }) {
  return (xs, q) => {
    const { id, type, options } = q

    // if (
    //   id === "nitrateClarificationQuestionNo" ||
    //   id === "nitrateClarificationQuestionYes" ||
    //   id === "nitrateClarificationQuestionUnsure"
    // ) {
    //   return xs
    // }

    let answer = answers[id]

    if (type === "CheckboxList") {
      const answer = answers[id]

      const listQuestions = options
        .filter((x) => x.value !== "none")
        .map(({ value, title }) => ({
          id: value,
          answer: answer.indexOf(value) >= 0 ? "Yes" : "No",
          questionId: id,
          title,
        }))

      return [...xs, ...listQuestions]
    } else if (Array.isArray(answer)) {
      answer = displayList(answer, options)
    } else if (options) {
      const opt = options.filter((opt) => opt.value === answer)
      if (opt.length) {
        answer = opt[0].title
        if (answer.includes("No")) {
          answer = formatting.no
        }
      }
    } else if (type === "CheckboxList") {
      console.log("checkbox list")
    }

    return [...xs, { id, answer }]
  }
}

export default function SummaryPage({ spec, answers, onEdit, onConfirm, client, assessmentId }) {
  const [summaryIds, setSummaryIds] = useState([])

  useEffect(() => {
    client
      .getSummary({ assessmentid: assessmentId })
      .then((response) => {
        setSummaryIds(response.summaryids)
      })
      .catch((e) => console.log(e))
  }, [assessmentId, client])

  // useEffect(() => {
  //   sessionStorage.removeItem("screenState")
  // }, [])

  const { repurchase } = answers
  const onEvent = useAudit({ type: "CONFIRM_ANSWERS_DISPLAYED" })
  const qs = questions(spec).filter((q) => q.type !== "virtual")

  const keyValues = qs.filter((q) => summaryIds.includes(q.id)).reduce(makeFoldQuestions({ questions: qs, answers }), [])

  const setModal = useContext(ModalContext)

  const showModal = () =>
    setModal(() => (
      <ConfirmModal
        onGoBack={() => setModal()}
        onConfirm={() => {
          onEvent({ type: "CONFIRM_ANSWERS_ATTESTATION" })
          setModal()
          onConfirm()
        }}
      />
    ))

  return (
    <div className="summary">
      <div className="list-container">
        <h1>
          {repurchase
            ? "Has anything changed since the last time you visited?"
            : "Please review all your answers. You can click on any answer to change it."}
        </h1>

        <div className="list">
          {keyValues.map(({ id, answer, questionId }) => (
            <Fragment key={id}>
              {/* Making sure we only display items included in the summaryQuestion object */}
              {summaryQuestion[id] && (
                <div key={id} className="summary-item">
                  <div className="summary-item-content">
                    <h2 className="summary-item-questions">{summaryQuestion[id]}</h2>
                    <p className="summary-item-answers">{answer}</p>
                  </div>
                  <button
                    aria-label="Edit"
                    onClick={() => {
                      onEdit(index(spec, questionId || editOverride[id] || id))
                    }}
                  >
                    <img src={icons["edit2"]} alt="" />
                    <span className="sr-only">{`edit ${id}`}</span>
                  </button>
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>

      <div className="button-container">
        <AssessmentButton onClick={() => showModal()} variant="secondary">
          I Checked My Answers
        </AssessmentButton>
      </div>
    </div>
  )
}
