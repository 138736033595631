import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { googleAuth, appleAuth, Auth } from "./Auth"
import Textfield, { WithLabel, WithNote, RequiredField } from "./Textfield"
import "../css/SignUp.css"
import { useDispatch } from "react-redux"
import { style, TermsLink, SignInLink, isPasswordValid, isEmailValid, isConfirmEmailValid, isConfirmPasswordValid } from "./signUpAssets"
import { authClient } from "../client"
import { assessmentStarted } from "../reducers"
import icons from "../icn/index"

import useResponsive from "../responsive"
import { AssessmentButton } from "./AssessmentComponents"

export function SignUpComponent({ onSubmit, children }) {
  const authed = useSelector((state) => state.auth.authed)
  const { desktop, mobile } = useResponsive()
  const dispatch = useDispatch()

  // State object to manage the form fields and their validation
  const [state, setState] = useState({
    email: "",
    emailValid: true,
    emailTouched: false,
    confirmEmail: "",
    confirmEmailValid: true,
    confirmEmailTouched: false,
    password: "",
    passwordValid: true,
    passwordTouched: false,
    confirmPassword: "",
    confirmPasswordValid: true,
    confirmPasswordTouched: false,
  })
  const [error, setError] = useState(null)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

  // Retrieve the email from sessionStorage when the component mounts
  useEffect(() => {
    const storedEmail = sessionStorage.getItem("email")
    if (storedEmail) {
      setState((prevState) => ({
        ...prevState,
        email: storedEmail,
        emailTouched: true, // Mark email as touched to avoid validation checks
        emailValid: true, // Assume stored email is valid
      }))
    }
  }, [])

  // Handle field changes and update the state
const handleFieldChange = (field, value) => {
  let newState = {
    ...state,
    [field]: value,
  }

  // Validate email fields
  if (field === "email" || field === "confirmEmail") {
    newState.emailValid = isEmailValid(newState.email)
    newState.confirmEmailValid = isConfirmEmailValid(newState.email, newState.confirmEmail)
    newState.confirmEmailTouched = true // Set touched to true for immediate feedback
  }

  // Validate password fields
  if (field === "password" || field === "confirmPassword") {
    newState.passwordValid = isPasswordValid(newState.password)
    newState.confirmPasswordValid = isConfirmPasswordValid(newState.password, newState.confirmPassword)
    newState.confirmPasswordTouched = true // Set touched to true for immediate feedback
  }

  // Update the state with the new values
  setState(newState)
}

  // Handle password visibility toggle
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  // Handle confirm password visibility toggle
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible)
  };

  // Handle confirmPassword field focus event
  const handleConfirmPasswordFocus = () => {
    if (!state.confirmPasswordTouched) {
      // Mark the confirmPassword field as touched when it receives focus
      setState((prevState) => ({
        ...prevState,
        confirmPasswordTouched: true,
      }))
    }
  }

  // Handle field blur events
  const handleFieldBlur = (field) => {
    if (field === "email") {
      // Perform email validation
      const isValid = isEmailValid(state.email)
      setState((prevState) => ({
        ...prevState,
        emailValid: isValid,
        emailTouched: true,
      }))
    } else if (field === "password") {
      // Perform password validation on blur
      const isValid = isPasswordValid(state.password)
      setState((prevState) => ({
        ...prevState,
        passwordValid: isValid,
        passwordTouched: true,
      }))
    } else if (field === "confirmPassword") {
      // Perform confirmation password validation on blur
      const isValid = isConfirmPasswordValid(state.password, state.confirmPassword)
      setState((prevState) => ({
        ...prevState,
        confirmPasswordValid: isValid,
        confirmPasswordTouched: true,
      }))
    } 
    else if (field === "confirmEmail") {
      // Handle confirm email field blur
      const isValid = isConfirmEmailValid(state.email, state.confirmEmail)
      setState(prevState => ({
        ...prevState,
        confirmEmailValid: isValid,
        confirmEmailTouched: true,
      }))
    }
  }

  // Check if all form fields are valid
  const isFormValid = () => {
    return (
      isEmailValid(state.email) &&
      isConfirmEmailValid(state.email, state.confirmEmail) &&
      isPasswordValid(state.password) &&
      isConfirmPasswordValid(state.password, state.confirmPassword)
    )
  }

  // Handle form submission
  const handleSubmit = async () => {
    if (isFormValid()) {
      //  ============================================ SUBMIT FORM ============================================
      try {
        const signInResult = await authClient.nativeCreateAccount(state.email, state.password)
        // If sign in is successful, proceed with dispatch
        if (signInResult.status === "ok") {
          dispatch(assessmentStarted())
          return
        }
      } catch (err) {
        // Handle any errors that occur during the sign-in process
        console.error("Error during native sign-in:", err)
        setError(err.message)
        return
      }
    } else {
      console.log("INVALID FORM")
      // Handle form submission errors or display validation messages
    }
  }

  // Authentication handlers
  const onApple = async (authType) => {
    await appleAuth(authType, true)
    onSubmit()
  }

  const onGoogle = async (authType) => {
    await googleAuth(authType, true)
    onSubmit()
  }

  const onGuest = () => {
    dispatch(assessmentStarted())
  }

  const desktopStyle = {
    margin: '24px 64px 24px 64px',
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: mobile ? "column" : desktop ? "row" : "column",
        ...desktop && desktopStyle,
      }}
    >
      {/* Left Side - User Input Fields */}
      <div className="sign-in" style={{ ...style.leftContainer, flex: desktop ? 1 : "unset" }}>
        <p className="required-text">*Field is required</p>
        <RequiredField label="Email Address">
          <Textfield
              type="text"
              placeholder="name@example.com"
              onChange={(e) => {
                if (state.emailTouched && !state.emailValid) {
                  setState((prevState) => ({
                    ...prevState,
                    emailValid: true,
                  }))
                }
                handleFieldChange("email", e.target.value)
              }}
              onBlur={() => handleFieldBlur("email")}
              className={`signup-email-input ${state.emailTouched && !state.emailValid ? "input-error" : ""}`}
           />
          {state.emailTouched && !state.emailValid ? (
            <div className="error">Invalid Email Address. Please enter a valid email in this format: example@gmail.com.</div>
          ) : null}
        </RequiredField>
        <RequiredField label="Confirm Email">
          <Textfield
            type="text"
            placeholder="name@example.com"
            onChange={(e) => handleFieldChange("confirmEmail", e.target.value)}
            onBlur={() => handleFieldBlur("confirmEmail")}
            className={`signup-email-input ${
              state.confirmEmailTouched && !state.confirmEmailValid && state.confirmEmail.length > 0 ? "input-error" : ""
            }`}
          />
          {state.confirmEmailTouched && !state.confirmEmailValid && state.confirmEmail.length > 0 && (
            <div className="error">Email and Confirm Email do not match. Please enter again.</div>
          )}
        </RequiredField>
        <RequiredField label="Password">
          <div className="password-field-container">
            <Textfield
              type={passwordVisible ? "text" : "password"}
              placeholder="password"
              onChange={(e) => handleFieldChange("password", e.target.value)}
              onBlur={() => handleFieldBlur("password")}
              className={`signup-password-input ${state.passwordTouched && (state.passwordValid ? "input-success" : (state.passwordTouched ? "input-error" : ""))}`}
            />
            {state.passwordValid && state.passwordTouched && (
              <img src={icons["green-check"]} className="icon-inside-input" alt="Valid"/>
            )}
            <button onClick={togglePasswordVisibility} className="password-toggle-button">
              <img src={passwordVisible ? icons["eye"] : icons["eye-slash"]} alt="Toggle visibility" />
            </button>
          </div>
          {state.passwordTouched && !state.passwordValid && (
            <div className="error">Please choose a more secure password.</div>
          )}
        </RequiredField>
        <RequiredField label="Confirm Password">
          <div className="password-field-container">
            <Textfield
              type={confirmPasswordVisible ? "text" : "password"}
              placeholder="password"
              onChange={(e) => handleFieldChange("confirmPassword", e.target.value)}
              onBlur={() => handleFieldBlur("confirmPassword")}
              className={`signup-confirm-password-input ${
                state.confirmPasswordTouched && !state.confirmPasswordValid && state.confirmPassword.length > 0 ? "input-error" : ""
              }`}
            />
            {state.confirmPasswordValid && state.confirmPasswordTouched && state.confirmPassword.length > 0 && (
              <img src={icons["green-check"]} className="icon-inside-input" alt="Valid" />
            )}
            <button onClick={toggleConfirmPasswordVisibility} className="password-toggle-button">
              <img src={confirmPasswordVisible ? icons["eye"] : icons["eye-slash"]} alt="Toggle visibility" />
            </button>
          </div>
          {state.confirmPasswordTouched && !state.confirmPasswordValid && state.confirmPassword.length > 0 && (
            <div className="error">Password and Confirm Password do not match. Please enter again.</div>
          )}
        </RequiredField>
        <AssessmentButton style={{ marginTop: "24px" }} variant="secondary" onClick={handleSubmit} disabled={!isFormValid()}>
          Create Account & Sign In
        </AssessmentButton>
        {error ? <div className="sign-in-error">{error}</div> : null}

      </div>

      {/* Divider */}
      <div
        style={{
          display: "flex",
          flexDirection: mobile ? "row" : "column",
          alignItems: "center", // Ensures vertical centering in desktop view
          justifyContent: "center",
          margin: "20px 0",
        }}
      >
        {/* Divider to the Left - Applicable for both Desktop and Mobile */}
        <div
          style={{
            height: mobile ? "2px" : "130px",
            width: mobile ? "40%" : "2px",
            backgroundColor: "#ccc",
            alignSelf: mobile ? "center" : "stretch", // Stretch for desktop to fill container height
            margin: mobile ? "0 10px 0 0" : "0 0 0 16px",
          }}
        ></div>

        {/* Text */}
        <p
          style={{
            margin: "16px 0 16px 0",
            fontSize: "1rem",
            fontWeight: "600",
            alignSelf: "center",
            padding: "0 10px",
          }}
        >
          Or
        </p>

        {/* Divider to the Right - Applicable for both Desktop and Mobile */}
        <div
          style={{
            height: mobile ? "2px" : "130px",
            width: mobile ? "40%" : "2px",
            backgroundColor: "#ccc",
            alignSelf: mobile ? "center" : "stretch", // Stretch for desktop to fill container height
            margin: mobile ? "0 0 0 10px" : "0 0 0 16px",
          }}
        ></div>
      </div>

      {/* Right Side - oAuth */}
      <div style={{ ...style.rightContainer, flex: desktop ? 1 : "unset" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "392px",
              margin: "0 28px 32px 28px",
            }}
          >
            <div
              style={{
                marginBottom: "1.5rem",
                marginTop: "1.5rem",
                textAlign: "center",
              }}
            >
              <SignInLink>Have an account? Sign in</SignInLink>
            </div>
            <div style={{ margin: desktop ? "0 28px" : undefined }}>
              <div style={{ margin: "0 0 16px 0" }}>
                <Auth
                  onSelect={(name, _) => {
                    if (name === "guest") {
                      onGuest()
                    } else if (name === "google") {
                      onGoogle()
                    } else if (name === "apple") {
                      onApple()
                    }
                  }}
                />
              </div>
            </div>
            <p
              style={{
                ...style.content.termsText,
                margin: 0,
                textAlign: "center",
              }}
            >
              By using your Google or Apple ID you accept our <TermsLink>terms of use</TermsLink> and{" "}
              <TermsLink>privacy policy</TermsLink>. If you continue as a guest, your answers will not be saved when you quit
              and you will need to restart.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
