import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import apple from "../icn/apple.png"
import google from "../icn/g-logo.png"
import { ReactComponent as ProfileIcon } from "../icn/profile.svg"
import { subscribe } from "../subscribers"
import { authClient } from "../client"
import store from "../store"

const loaded = {}

function load(src, cb) {
  if (loaded[src]) {
    cb()
    return
  }

  loaded[src] = true

  const script = document.createElement("script")
  script.src = src
  script.onload = () => {
    if (cb) cb()
  }

  document.body.appendChild(script)
}

let didAppleInit = false

function makeAppleInit(config) {
  function initApple() {
    if (!config.APPLE_CLIENT_ID) {
      return
    }

    if (!didAppleInit) {
      didAppleInit = true
      window.AppleID.auth.init({
        clientId: config.APPLE_CLIENT_ID,
        scope: "name email",
        redirectURI: `${window.location.origin}/auth/apple/success`,
        usePopup: true,
      })
    }
  }

  return initApple
}

function popup(target, name) {
  const q = function(e, t) {
    var i = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.screen.width,
      o = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : window.screen.height
    return {
      left: i / 2 - e / 2 + window.screenLeft,
      top: o / 2 - t / 2 + window.screenTop,
    }
  }
  const top = q(700, 700).top
  const left = q(700, 700).left
  const features = `width=700,height=700,top=${top},left=${left},resizable=no,location=no,menubar=no`
  window.open(target, name, features)
}

export function googleAuth(mode, transfer = false) {
  store.dispatch({ type: "GOOGLE_AUTH_START", mode, transfer })
  popup(buildGoogleRedirect(), "GoogleAuth")
  return subscribe("google")
}

export function appleAuth(mode, transfer = false) {
  store.dispatch({ type: "APPLE_AUTH_START", mode, transfer })
  window.AppleID.auth.signIn()
  return subscribe("apple")
}

function buildGoogleRedirect() {
  const params = {
    scope: "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
    access_type: "offline",
    redirect_uri: `${window.location.origin}/oauth/google`,
    response_type: "code",
    client_id: "934195914927-gs5ceipt1i17l9tfhc3ab2nlbv5m6hf5.apps.googleusercontent.com",
    prompt: "consent",
  }
  const base = "https://accounts.google.com/o/oauth2/v2/auth"

  const qs = Object.entries(params)
    .map(([k, v]) => `${k}=${v}`)
    .join("&")
  return encodeURI(`${base}?${qs}`)
}

function ImageButton({ src, children, onClick, image, disabled }) {
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 52px 0 12px",
    height: "56px",
    width: "clamp(365px, 100%, 500px)",
    fontFamily: "Open Sans",
    fontSize: "22px",
    fontWeight: 600,
    lineHeight: "36px",
    border: "none",
    borderRadius: "28px",
    backgroundColor: "transparent",
    color: "var(--primary)",
    transition: "box-shadow 150ms ease",
    border: "3px solid rgba(7,20,57, 1)",
    opacity: disabled ? 0.5 : 1,
    cursor: disabled ? "not-allowed" : "pointer",
  }

  return (
    <button style={style} onClick={onClick} disabled={disabled}>
      {image || <img style={{ width: "32px", padding: "4px", marginRight:"2px" }} src={src} />}
      <span style={{ flex: "1 1 auto" }}>{children}</span>
    </button>
  )
}

export function Auth({ onSelect, onContinue, showGuestLogin = true }) {
  console.log("Auth Component Rendered!")
  const dispatch = useDispatch()
  const config = useSelector((state) => state.config)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false) // Initialize with the button enabled.
  const buttonCooldown = 1000 // 1 second cooldown

  const handleButtonClick = async (authType, authFunction) => {
    if (isButtonDisabled) return

    onSelect(authType, async (mode) => {
      const ret = await authFunction(mode)
      onContinue(ret)
    })

    // After button click, start cooldown
    setIsButtonDisabled(true)
    setTimeout(() => {
      setIsButtonDisabled(false)
    }, buttonCooldown)
  }

  // useEffect(() => {
  //   setIsButtonDisabled(true)
  //   const enableButtonAfterCooldown = setTimeout(() => {
  //     setIsButtonDisabled(false)
  //   }, buttonCooldown)

  //   // On component mount, load the apple auth script
  //   load('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', makeAppleInit(config))

  //   // Clear the timer if the component unmounts before the cooldown completes.
  //   return () => clearTimeout(enableButtonAfterCooldown)
  // }, [config])

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div style={{ marginBottom: "20px" }}>
        <ImageButton src={google} onClick={() => handleButtonClick("google", googleAuth)} disabled={isButtonDisabled}>
          Continue with Google
        </ImageButton>
      </div>
      <div>
        <ImageButton src={apple} onClick={() => handleButtonClick("apple", appleAuth)} disabled={isButtonDisabled}>
          Continue with Apple
        </ImageButton>
      </div>
      {showGuestLogin && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "600",
              fontSize: "1.5rem",
              margin: "8px 0",
              color: "rgba(7, 20, 57, 1)",
            }}
          >
            Or
          </div>
          <div>
            <ImageButton
              image={<ProfileIcon width={40} fill="black" />}
              onClick={() => handleButtonClick("guest", authClient.guestLogin)}
              disabled={isButtonDisabled}
            >
              Continue as a Guest
            </ImageButton>
          </div>
        </>
      )}
    </div>
  )
}
