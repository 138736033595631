import React from 'react'

const ProgressBar = ({ current, total }) => {
    const progress = (current / total) * 100;

    return(
        <div className="progress-bar">
            <div className="progress-bar__fill" data-testid="progress-bar-fill" style={{ width: `${progress}%` }}></div>
        </div>
    )
}

export default ProgressBar;