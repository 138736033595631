import { createContext } from 'react'
import { useMediaQuery } from 'react-responsive'

const ResponsiveContext = createContext()

export default function useResponsive() {
  const mobile = useMediaQuery({maxWidth: 767})
  const tablet = useMediaQuery({minWidth: 768, maxWidth: 991})
  const desktop = useMediaQuery({minWidth: 992})

  return {mobile, tablet, desktop}
}
