// node = {id, type, properties, children?}

function nodeHasChildren(node) {
  return node !== undefined && node.children !== undefined && node.children.length > 0
}

export function getNodeById(root, id) {
  if (root === undefined) {
    return undefined
  }
  if (root.id === id) {
    return root
  }
  if (!nodeHasChildren(root)) {
    return undefined
  }
  for (let i = 0; i < root.children.length; i++) {
    let found = getNodeById(root.children[i], id)
    if (found) {
      return found
    }
  }
  return undefined
}

export function findChild(root, id) {
  if (!root) { return undefined }
  if (root.children) {
    for (const c of root.children) {
      const found = getNodeById(c, id)
      if (found) {
        return found
      }
    }
  }
  return undefined
}

// find the screen index containing the component with the id
export function findScreenIndex(spec, id) {
  if (!spec) { return -1 }
  if (spec.children) {
    for (let i = 0; i < spec.children.length; i++) {
      const found = findChild(spec.children[i], id)
      if (found) {
        return i
      }
    }
  }
  return -1
}

export function updateNodeById(root, id, update) {
  if (root === undefined) {
    return undefined
  }
  if (root.id === id) {
    return {...root, ...update}
  }
  if (!nodeHasChildren(root)) {
    return root
  }
  return {...root, children: root.children.map(c => updateNodeById(c, id, update))}
}

// returns the questions starting from root
export function questions(root, ret=[]) {
  walk(root, n => {
    if (n.id && n.type !== "Assessment" && n.type !== "Screen") {
      ret.push(n)
    }
    if (Array.isArray(n.options)) {
      for (const option of n.options) {
        if (option.id) {
          ret.push(option)
        }
      }
    }
  })
  return ret
}

// calls callback on each node in the tree
export function walk(root, callback) {
  callback(root)
  if (Array.isArray(root.children)) {
    for (const c of root.children) {
      walk(c, callback)
    }
  }
}
