import { useState, useEffect, useRef } from "react"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export function useGetAssessmentById(api, id) {
  const [assessment, setAssessment] = useState(undefined)
  useEffect(() => {
    async function getById() {
      const response = await api.getById(id)
      setAssessment(response)
    }
    getById()
  }, [id])
  return [assessment, setAssessment]
}

export const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const initialStatus = useRef(navigator.onLine) 
  const toastId = useRef(null)  // Store the toast ID

  const showConnectivityLossToast = () => {
    if (toastId.current !== null) {
      toast.dismiss(toastId.current)
    }
    toastId.current = toast.error("Network connectivity lost. Please check your internet connection.", { className: "connectivity-loss-popup" })
  }

  // Modified this function
  const showConnectivityRestoredToast = () => {
    if (toastId.current !== null) {
      toast.dismiss(toastId.current)
      toastId.current = null
    }
  }

  const updateNetworkStatus = () => {
    if (initialStatus.current === navigator.onLine) {
      return 
    }

    setIsOnline(navigator.onLine)
    initialStatus.current = navigator.onLine

    if (navigator.onLine) {
      showConnectivityRestoredToast()
    } else {
      showConnectivityLossToast()
    }
  }

  useEffect(() => {
    window.addEventListener('online', updateNetworkStatus)
    window.addEventListener('offline', updateNetworkStatus)

    return () => {
      window.removeEventListener('online', updateNetworkStatus)
      window.removeEventListener('offline', updateNetworkStatus)
    }
  }, [])

  return { isOnline }
}

export function useStateWithSessionStorage(key, value) {
  // get saved state or initialize with value
  const init = () => {
    const saved = window.sessionStorage.getItem(key)
    if (saved) {
      return JSON.parse(saved)
    }
    return value
  }

  const [state, setState] = useState(init)

  // save state on change
  useEffect(() => {
    if (state !== undefined) {
      window.sessionStorage.setItem(key, JSON.stringify(state))
    }
  }, [state])

  // cleanup on unmount
  useEffect(() => {
    return () => {
      window.sessionStorage.removeItem(key)
    }
  }, [])

  return [state, setState]
}