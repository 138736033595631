import { useState, useEffect } from 'react'
import { Checkbox } from '../../components/AssessmentComponents'
import { ShippingAddressInput } from './ShippingInformation'

// CSS
import "../../css/PaymentInformation.css"


function NumberFieldWithLabel({ id, label, err, onChange, value, required=false, icons=false }) {
  const [touched, setTouched] = useState(false)

  const errorStyling = required && (!value || err)
  const displayErrorClassName = (errorStyling && touched) ? "error-field-style" : ""

  return (
      <div>
        <label htmlFor={id}>
          {label}
          {required && (<span className="required-field">*</span>)}
        </label>
        <div className={`text-field ${icons ? "input-container" : ""} ${displayErrorClassName}`}>
          <input type="text" onChange={onChange} value={value} onBlur={() => setTouched(true)}/>
          {icons && (
            <span className="icon-container">
              <i className="amex-card-icon"></i>
              <i className="visa-card-icon"></i>
              <i className="mastercard-icon"></i>
            </span>
          )}
        </div>
    </div>
  )
}


const CardComponents = ({ onPaymentInfo, setPaymentMethodDetails }) => {
  const [state, setState] = useState({cardNumber: '', cardExpiry: '', cardCvc: ''})
  
  const update = (k, v) => {
    setState(s => ({...s, [k]: v}))
  }
  
  // Updating the state as we change the different input fields
  const handleState = (field, value) => {
    const text = value.trimStart()
    update(field, text)
  }
  
  const checkCardNumber = number => {
    const regex = /^[0-9]{16}$/
    return regex.test(number)
  }
  
  const checkExpirationDate = number => {
    const regex = /^[0-9]+(\/\[0-9])*$/
    return regex.test(number)
  }
  
  const checkCVCCode = number => {
    const regex = /^[0-9]{3}$/
    return regex.test(number)
  }
  
  let completeValidation = checkCardNumber(state.cardNumber) && checkExpirationDate(state.cardExpiry) && checkCVCCode(state.cardCvc)

  useEffect(() => {
    onPaymentInfo(completeValidation)
  }, [completeValidation])

  const determineCardBrand = (number) => {
      // Implement logic to determine card brand
      if (number.startsWith("3")) return "amex"
      if (number.startsWith("4")) return "visa"
      if (number.startsWith("5")) return "mastercard"
      if (number.startsWith("6")) return "discover"

      // Add other card types as needed
      return "Unknown"
  };

  useEffect(() => {
      if (state.cardNumber.length >= 16) { // Check if the card number is sufficiently long
          const brand = determineCardBrand(state.cardNumber)
          const last4 = state.cardNumber.slice(-4);
          setPaymentMethodDetails({ brand, last4 })
      }
  }, [state.cardNumber, setPaymentMethodDetails])
  
  return (
    <div className="info-grid-container">
      <div className="info-grid-subcontainer_col1">
        <NumberFieldWithLabel 
          id="cardNumber"
          label="Card Number"
          onChange={e => handleState("cardNumber", e.target.value)}
          value={state.cardNumber}
          required={true}
          icons={true}
          err={!checkCardNumber(state.cardNumber)}
        />
      </div>
      <div className="info-grid-subcontainer_col2">
        <NumberFieldWithLabel
          id="cardExpiry"
          label="Expiration date (MM/YY)"
          onChange={e => handleState("cardExpiry", e.target.value)}
          value={state.cardExpiry}
          required={true}
          err={!checkExpirationDate(state.cardExpiry)}
        />
        <NumberFieldWithLabel 
          id="cardCvc"
          label="CVC"
          onChange={e => handleState("cardCvc", e.target.value)}
          value={state.cardCvc}
          required={true}
          err={!checkCVCCode(state.cardCvc)}
        />
      </div>
    </div>
   
  )
}


export function PaymentMethod({ setPaymentMethodDetails, billingAddress, onCheck, onPaymentInfo, onCompleteChange, onChangeBillingAddress, paymentAddressSame }) {

  let completeValidation = onCompleteChange && (paymentAddressSame || billingAddress.complete)

  useEffect(() => {
    onCompleteChange(completeValidation)
  }, [completeValidation])

  return (
    <main>
      <div>
        <Checkbox 
          title="Billing address is same as shipping address"
          value="same"
          variant="primary"
          className="same-address-checkbox"
          onCheck={onCheck}
          checked={paymentAddressSame}
        />

      {!paymentAddressSame && (
        <div>
          <h2 className="billing-address-title">Enter Billing address</h2>
          <ShippingAddressInput onChange={onChangeBillingAddress}/>
          <hr className="components-divider"/>
        </div>
      )}

      </div>
      <CardComponents onPaymentInfo={onPaymentInfo} setPaymentMethodDetails={setPaymentMethodDetails} />
    </main>
  )
}

