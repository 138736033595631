import { useContext, useEffect, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

import { AssessmentButton } from './components/AssessmentComponents'
import { WarningModal } from './components/WarningModal';
import ModalContext from './modal'


export function useBlocker( blocker, when = true ) {
  const { navigator } = useContext( NavigationContext );

  useEffect( () => {
    if ( !when ) return;

    const unblock = navigator.block( ( tx ) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker( autoUnblockingTx );
    } );

    return () => {
      if(unblock) {
        unblock();
      }
    };
  }, [ navigator, blocker, when ] );
}

const buttonContainer = {
  width: "100%",
  margin: "0 auto",
  paddingTop: "16px",
}

let skipNext = false

export function skipPrompt() {
  skipNext = true
}

export function usePrompt(msg, when, onEvent, location, blockBrowserBackButton=false) {
  const setModal = useContext(ModalContext)

  // Adding condition here to disable the browser back button in Order confirmation screen (checkout workflow) (Rock-73)
  // Note: we should avoid doing this as it's an anti-pattern and leads to poor user experience.
  const blocker = useCallback(
    (tx) => {
      if (skipNext && !blockBrowserBackButton) {
        skipNext = false
        tx.retry()
        return
      }

      if (tx.location.state?.shouldSkipPrompt && !blockBrowserBackButton) {
        tx.retry()
        return
      }
      
      const onClose = () => setModal()

      const onConfirm = () => {
        setModal()
        onEvent({type: "EXIT_CONFIRM"})
        tx.retry()
      }

      const handleLeave = () => {
        setModal()
        onEvent({type: "PURCHASE_SCREEN_DISPLAYED"})
        tx.retry()
      }

      const checkoutExitModal = () => (<WarningModal handleLeave={handleLeave} handleContinue={onClose}/>)

      if (blockBrowserBackButton) {
        // Redirect users to the Welcome screen if clicking on the logo when in Order confirmation screen
        if(tx.location.state?.canNavigate) {
          onConfirm()
        }
      } else {
        
        const createModal = () => (
          <>
            <h3 style={{color: "var(--secondary)"}}>
              You are about to leave the health survey
            </h3>
      
            <div style={{padding: "0 0 40px 0"}}>
              <span style={{lineHeight: 2}}>
                If you leave, your information will not be saved. Are you sure you want to exit?
              </span>
            </div>
      
            <div style={buttonContainer}>
              <AssessmentButton onClick={onConfirm} variant="secondary">Leave</AssessmentButton>
            </div>
      
            <div style={buttonContainer}>
              <AssessmentButton onClick={onClose} variant="secondary">Close</AssessmentButton>
            </div>
          </>
        )

        if (location === "assessment") {
          setModal(createModal)
        }
      
        if (location === "checkout") {
          setModal(checkoutExitModal)
        }
      }
    },
  [msg, location, blockBrowserBackButton]
  );

  useBlocker(blocker, when);
}
