import React from "react"
import { ToastContainer } from "react-toastify"
import Modal from "react-modal"
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom"
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { useNetworkStatus } from "./hooks"
import { CloseButton } from "./modal"
import LandingPage from "./pages/LandingPage"
import AssessmentPage from "./pages/Assessment"
import Outcome from "./pages/Outcome" // todo is this unused here?
import SignIn from "./pages/SignIn"
import CreateAccount from "./pages/CreateAccount"
import Experimental from "./pages/Experimental"
import Playground from "./pages/Playground"
import ReturnPage from "./pages/Return"
import ModalContext from "./modal"
import Checkout from "./pages/CheckoutTmp"
import QRCodePage from "./pages/QRCode"
import Unauthorized from "./pages/errorPages/401Page"
import ForbiddenPage from "./pages/errorPages/403Page"
import PageNotFound from "./pages/errorPages/404Page"
import MethodNotAllowed from "./pages/errorPages/405Page"
import RequestTimeout from "./pages/errorPages/408Page"
import InternalServerError from "./pages/errorPages/500Page"
import BadGateway from "./pages/errorPages/502Page"

import "./App.css"
import "./css/Modal.css"
import history from "./history"
import store from "./store"
import { client, authClient } from "./client"
import config from "./config"

fetch("/config/config.json")
  .then((res) => res.json())
  .then((config) => store.dispatch({ type: "FETCH_CONFIG_SUCCESS", config }))

Modal.setAppElement(document.getElementById("root"))

class ModalProvider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      stack: [],
    }
  }

  setModal = (modal) => {
    this.setState((state) => {
      const { stack } = state
      if (!modal) {
        const [_current, ...rest] = stack
        return { stack: rest }
      }
      return { stack: [modal, ...stack] }
    })
  }

  render() {
    const { stack } = this.state
    const isModalOpen = stack.length > 0
    // Specifying the type of modal for now to use different styling.
    // TODO: Once we enhance the old modal as the new one, we'll remove this and leave just one class.
    const isCheckoutWorkflow = window.location.href.includes("checkout")
    const exitModalClass = isCheckoutWorkflow ? "exit-checkout-modal" : "exit-assessment-modal"

    const closeModal = () => this.setModal()

    return (
      <>
        <ModalContext.Provider value={this.setModal}>{this.props.children}</ModalContext.Provider>

        <Modal
          className={exitModalClass}
          overlayClassName="overlay"
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          showCloseButton={!isCheckoutWorkflow}
        >
          {!isCheckoutWorkflow && (
            <div style={{ position: "absolute", right: "16px", top: "16px" }}>
              <CloseButton onClick={closeModal} />
            </div>
          )}

          {isModalOpen && stack[0]()}
        </Modal>
      </>
    )
  }
}

function Root() {
  return (
    <ModalProvider>
      <Outlet />
    </ModalProvider>
  )
}

function Debug({ children }) {
  // shows debug information
  return (
    <div
      style={{
        color: "red",
        background: "white",
        border: "solid 1px black",
        minWidth: "300px",
        display: "inline-block",
        padding: "16px",
        position: "fixed",
        bottom: "16px",
        right: "16px",
      }}
    >
      {children}
    </div>
  )
}

const showDebug = false

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Root />}>
        <Route
          index
          element={
            <ModalProvider key="landing">
              <LandingPage client={client} />
            </ModalProvider>
          }
        />
        <Route
          path="assessment/:assessmentId/"
          element={
            <ModalProvider key="assessment">
              <AssessmentPage />
            </ModalProvider>
          }
        />
        <Route path="return/:assessmentId" element={<ReturnPage />} />
        <Route path="create-account" element={<CreateAccount authClient={authClient} />} />
        <Route path="sign-in" element={<SignIn authClient={authClient} />} />
        <Route path="experimental" element={<Experimental client={client} />} />
        <Route path="play" element={<Playground />} />
        <Route path="outcome" element={<Outcome />} />
        <Route path="checkout/:assessmentId" element={<Checkout />} />
        <Route path="qr/:assessmentId" element={<QRCodePage />} />
        <Route path="/401" element={<Unauthorized />} />
        <Route path="/403" element={<ForbiddenPage />} />
        <Route path="/405" element={<MethodNotAllowed />} />
        <Route path="/408" element={<RequestTimeout />} />
        <Route path="/500" element={<InternalServerError />} />
        <Route path="/502" element={<BadGateway />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  )
}

function App() {
  const { isOnline } = useNetworkStatus()
  return (
    <Provider store={store}>
      <div className="App">
        {showDebug && (
          <Debug>
            <pre>config: {JSON.stringify(config, null, 2)}</pre>
          </Debug>
        )}
        <HistoryRouter history={history}>
          <AppRoutes />
        </HistoryRouter>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={false}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        closeButton={false}
        draggable={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </Provider>
  )
}

export default App
