import { useState, useEffect } from 'react'

import { OptionSelect } from '../../components/AssessmentComponents'
import Textfield from '../../components/Textfield'
import useResponsive from '../../responsive.js'
import { options } from './states'

// CSS
import "../../css/ShippingInformation.css"


const DEBUG = false


function StateSelect({value, onChange, required=false}) {
  return (
    <div>
      <label htmlFor="state">
        State
        {required && (<span className='required-field'>*</span>)}
      </label>
      <OptionSelect id="state" variant="primary" options={options} value={value} onChange={onChange} placeholder="" />
    </div>
  )
}


export function ShippingAddressInput({onChange=(state) => {}, isUserAuthenticated }) {
  let initState = {
    firstname: "",
    lastname: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    zip: ""
  }

  if (DEBUG) {
    initState = {
      firstname: "charlie",
      lastname: "brown",
      line1: "123 street",
      line2: "",
      city: "Houston",
      state: "TX",
      zip: "12345"
    }
  }

  
  const [state, setState] = useState(initState)
  const [saveAddress, setSavedAddress] = useState(false)

  // We aso need to handle this in the BE to update the user's addresses
  const handleSavingAddress = () => {
    setSavedAddress(!saveAddress)
  }


  const update = (k, v) => {
    setState(s => ({...s, [k]: v}))
  }
  
  const { mobile } = useResponsive()
  
  const checkZipCode = s => {
    const re = /^[0-9]{5}$/
    return re.test(s)
  }

  const handleZipChange = (e) => {
    const next = e.target.value
    if (next.length > 5) {
      return
    }
    update("zip", e.target.value)
  }

  // Updating the state as we change the different input fields
  const handleState = (field, value) => {
    const text = value.trimStart()
    update(field, text)
  }

  const checkValue = (text) => {
    const name = text.trimStart()
    const regex = /^[a-zA-Z]+( [a-zA-Z]+)*$/
    return regex.test(name)
  }

  // Removing white spaces at the beginning and allowing more than one word if needed
  const checkNameValues = (text) => {
    const name = text.trimStart()
    if (name.length > 150) {
      return
    }

    const regex = /^[a-zA-Z]+( [a-zA-Z]+)*$/
    return regex.test(name)
  }

  const checkAddress = (text) => {
    // Trim first space and check if there's at least one number
    const name = text.trimStart()
    const hasNumber = /\d/.test(name)
    
    // Check if there's at least one word
    const wordRegex = /[a-zA-Z]/
    const hasWord = wordRegex.test(name)

    // Check if all conditions are met
    const isAddressValid = hasNumber && hasWord

    return isAddressValid
  }

  let complete = (
    checkNameValues(state.firstname) &&
    checkNameValues(state.lastname) &&
    checkAddress(state.line1) &&
    checkValue(state.city) &&
    state.state && checkZipCode(state.zip)
    )

  useEffect(() => {
    onChange({...state, complete})
  }, [state])



  return (
    <>
      <div className="info-checkbox-container">      
        <div className="info-error-text">* Field is required</div>

        { !mobile && isUserAuthenticated && (
          <div className="save-address-checkbox">
            <input type="checkbox" id="saveAddress" name="save-address" value={saveAddress} onChange={handleSavingAddress}/>
            <label for="saveAddress">Save this address to my account</label>
          </div>
        )}
      </div>

      <div className="info-grid-container">
        <div className="info-grid-subcontainer_col2">
          <TextfieldWithLabel 
            id="firstname"
            label="First Name"
            onChange={e => handleState("firstname", e.target.value)}
            value={state.firstname}
            required={true}
            err={!checkNameValues(state.firstname)}
          />
          <TextfieldWithLabel
            id="lastname"
            label="Last Name"
            onChange={e => handleState("lastname", e.target.value)}
            value={state.lastname}
            required={true}
            err={!checkNameValues(state.lastname)}
          />
          <TextfieldWithLabel 
            id="line1"
            label="Address Line 1"
            onChange={e => handleState("line1", e.target.value)}
            value={state.line1}
            required={true}
            err={!checkAddress(state.line1)}
          />
          <TextfieldWithLabel id="line2" label="Address Line 2" onChange={e => update("line2", e.target.value)} value={state.line2} />
        </div>

        <div className="info-grid-subcontainer_col3">
          <TextfieldWithLabel
            id="city"
            label="City"
            onChange={e => update("city", e.target.value)}
            value={state.city}
            required={true}
            err={!checkValue(state.city)}
          />
          <StateSelect id="state" onChange={(value) => update("state", value)} value={state.state} required={true} />
          <TextfieldWithLabel 
            id="zip"
            label="Zip Code"
            value={state.zip}
            onChange={handleZipChange}
            required={true}
            err={!checkZipCode(state.zip)}
          />
        </div>

        { mobile && isUserAuthenticated && (
          <div className="save-address-checkbox">
            <input type="checkbox" id="saveAddress" name="save-address" value={saveAddress} onChange={handleSavingAddress} />
            <label for="saveAddress">Save this address to my account</label>
          </div>
        )}
      </div>
    </>
  )
}

export function TextfieldWithLabel({ id, label, err, onChange, value, required=false }) {
  const [touched, setTouched] = useState(false)

  const errorStyling = required && (!value || err)
  const displayErrorClassName = (errorStyling && touched) ? "error-field-style" : ""

  // Check long values for first and last name fields
  const isFieldName = id === 'firstname' || id === 'lastname'
  const hasLongValue = value?.length > 150

  return (
      <div>
        <label htmlFor={id}>
          {label}
          {required && (<span className="required-field">*</span>)}
        </label>
        <Textfield
          id={id} 
          className={displayErrorClassName}
          onChange={onChange}
          value={value}
          required={required}
          onBlur={() => setTouched(true)}
        />
          {/* Commenting this out for now, we may want to implement it later */}
          {/* {touched && isRequired && !value && (
            <span className="error-text">{label} is required</span>
          )} */}

          {(touched && err && isFieldName && hasLongValue) && (
            <span  className="error-text">{`${label} can not be greater than 150 characters.`}</span>
          )}
    </div>
  )
}


export function ShippingAddress({onChange, isUserAuthenticated}) {

  const handleChange = e => {
    let address = {complete: e.complete}
  
    if (e.complete) {
      address.firstName =  e.firstname
      address.lastName =  e.lastname
      address.line1 =  e.line1
      address.line2 =  e.line2
      address.city =  e.city
      address.state =  e.state
      address.postal_code =  e.zip
      address.country =  "US"
    }
    onChange(address)
  }

  return (
    <div className="shipping-address-container">
      <ShippingAddressInput onChange={handleChange} isUserAuthenticated={isUserAuthenticated}  />
    </div>
  )
}

