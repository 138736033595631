import store from './store'
import rootReducer, { appleAuthSuccess, googleAuthSuccess } from './reducers'


export const subscribers = {}


function createPromise() {
  let resolve, reject;
  const promise = new Promise((res, rej) => {
    resolve = res
    reject = rej
  })

  return {
    promise,
    resolve,
    reject,
  }
}

export function subscribe(event, cb) {
  // if (!subscribers[event]) {
  //   const p = createPromise()
  //   subscribers[event] = p
  // }

  // TODO: cleanup existing?
  const p = createPromise()
  subscribers[event] = p
  return subscribers[event].promise
}

export function resolve(event, val) {
  const p = subscribers[event]
  console.log("resolve", event, p, val)
  if (p) {
    subscribers[p] = 0
    p.resolve(val)
  }
}

