import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"

// Local dependencies and utilities
import { authClient } from "../client"
import { assessmentStarted } from "../reducers"
import Footer from "../components/Footer"
import landingPageImage from "../img/landing-page-image.png" // Tell webpack this JS file uses this image
import StendraLogo from "../img/avanafil-logo.svg"

// CSS
import "../css/LandingPage.css"

// Actual landing page
export default function LandingPage({ client }) {
  const dispatch = useDispatch()
  // const assessment = useSelector(state => state.assessment) TODO: figure out useSelector
  // const auth = useSelector(state => state.auth) TODO: figure out if we need this
  const [searchParams] = useSearchParams()
  // const clinician = searchParams.get("clinician")
  // const [participantID, setParticipantID] = useState(null)
  // const recordMetric = useMetrics()
  const invite = searchParams.get("invite")

  // TODO: integrate this when we get clinicians
  useEffect(() => {
    sessionStorage.removeItem("screenState")
    sessionStorage.removeItem("dflRead")
    sessionStorage.removeItem("OutcomeFlow_showDFL")
    sessionStorage.removeItem("OutcomeFlow_inputting")
    sessionStorage.removeItem("OutcomeFlow_readDFL")
    sessionStorage.removeItem("OutcomeFlow_showSignInScreen")
    sessionStorage.removeItem("participantID")
  }, [])

  useEffect(() => {
    authClient.getToken()
  }, [])

  const start = async () => {
    if (!invite) {
      // this allows the assessment to be taken without an invite email, and is for dev purposes only.
      await authClient.guestLogin()
      dispatch(assessmentStarted())
    }
    if (invite) {
      await authClient.getToken()
      dispatch(assessmentStarted())
    }
  }

  const navigate = useNavigate()
  const toSignIn = () => {
    navigate("/sign-in")
  }

  return (
    <div className="welcome">
      <header className="welcome-header">
        <img src={StendraLogo} className="welcome-logo" alt="Stendra Logo" />
        <button onClick={toSignIn} className="signin-button">
          Sign in
        </button>
      </header>

      <main className="content">
        <section className="body">
          <h1 className="landing-title">Welcome to the Avanafil OTC Self-Check</h1>
          <p>
            This self-check must be completed to determine if Avanafil OTC is appropriate for you to use. It is only for men
            18 years of age and older. If the results of this self-check confirm your eligibility, you will be granted access
            to purchase Avanafil OTC.
          </p>
          <p className="legal">For investigational use only</p>
        </section>
        <section>
          <div className="cta">
            <p>Everything in this self-check is secure and private. It is just for you.</p>

            {/* Put this condition back when adding SAP */}
            {/* { invite && (
                <> */}
            <button onClick={start}>Begin</button>
            <img src={landingPageImage} alt="Landing page" />
            {/* </> 
              )} */}
          </div>
          <div className="signin-link">
            <a onClick={toSignIn} href="">
              Already have an account? Sign in
            </a>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  )
}
