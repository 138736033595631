import { DefaultAssessmentService, DefaultAuthService, DefaultCheckoutService } from "./service"
import { jwtDecode } from "jwt-decode"
import store from "../store"

function Mutex() {
  let locked = false
  let waiting = []

  function lock() {
    if (!locked) {
      locked = true
      return
    }

    let unlocked
    const p = new Promise((r) => (unlocked = r))
    waiting.push(unlocked)

    return p
  }

  function unlock() {
    if (!waiting.length) {
      locked = false
      return
    }

    const next = waiting.pop()
    next(1)
  }

  return {
    lock,
    unlock,
  }
}

function getSearchParams() {
  const ret = {}
  for (const [k, v] of window.location.search
    .slice(1)
    .split("&")
    .map((x) => x.split("="))) {
    ret[k] = v
  }
  return ret
}

function decodeToken(token) {
  try {
    const decoded = jwtDecode(token)
    console.log(decoded)
    console.log(decoded.email)
    sessionStorage.setItem("email", decoded.email)
  } catch (e) {
    console.log("error decoding token", e)
    return null
  }
}

function AuthClient(tok) {
  const mu = Mutex()
  let _token = tok

  async function _getToken() {
    const { invite, clinician } = getSearchParams()

    const res = await fetch("/auth/token", {
      method: "POST",
      body: JSON.stringify({
        invite,
        clinician,
      }),
    })
    const token = await res.json()
    return token
  }

  function setToken(token) {
    sessionStorage.setItem("token", JSON.stringify(token))
    decodeToken(JSON.stringify(token))
    _token = token
  }

  async function getToken() {
    await mu.lock()
    if (!_token) {
      const t = await _getToken()
      setToken(t)
    }

    mu.unlock()
    return _token.token
  }

  async function fetchWithToken(req) {
    const token = await getToken()
    if (token) {
      req.headers.append("authorization", token)
    }
    return fetch(req)
  }

  async function guestLogin() {
    const token = await _getToken()
    setToken(token)
    sessionStorage.removeItem("authed")

    store.dispatch({ type: "GUEST_LOGIN_SUCCESS", token })
  }

  async function appleLogin(authorization, mode, transfer) {
    const tok = await getToken()

    const opts = {
      method: "POST",
      headers: {
        authorization: tok,
      },
      body: JSON.stringify({
        mode,
        transfer,
        origin: window.location.origin,
        ...authorization,
      }),
    }

    const res = await fetch("/auth/apple/success", opts)
    const { status, token, history } = await res.json()
    setToken(token)

    return { status, token, history }
  }

  // native account creation
  async function nativeCreateAccount(email, password) {
    let tknObj = JSON.parse(sessionStorage.getItem("token"))
    console.log("token object", tknObj)
    const opts = {
      method: "POST",
      headers: {
        authorization: tknObj.token,
      },
      body: JSON.stringify({
        email,
        password,
        origin: window.location.origin,
      }),
    }
    try {
      const res = await fetch("/auth/native-signup", opts)

      if (!res.ok) {
        const errorResponse = await res.json()
        console.log("Error response:", errorResponse.message)
        throw new Error(`${errorResponse.message}`)
      }

      const { token } = await res.json()
      setToken(token)

      return { status: "ok", token } // Return the relevant information
    } catch (err) {
      console.error("Error during native sign-in:", err)
      throw err // Re-throw the error to be handled by the caller
    }
  }

  async function nativeSignIn(email, password) {
    const opts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, origin: window.location.origin }),
    }

    try {
      const res = await fetch("/auth/native-login", opts)

      if (!res.ok) {
        const errorResponse = await res.json()
        console.log("Error response:", errorResponse.message)
        throw new Error(`${errorResponse.message}`)
      }

      const { token } = await res.json()
      setToken(token)

      return { status: "ok", token } // Return the relevant information
    } catch (err) {
      console.error("Error during native sign-in:", err)
      throw err // Re-throw the error to be handled by the caller
    }
  }

  async function googleLogin(code, mode, transfer) {
    const tok = await getToken()

    const opts = {
      method: "POST",
      headers: {
        authorization: tok,
      },
      body: JSON.stringify({
        origin: window.location.origin,
        code,
        mode,
        transfer,
      }),
    }

    const res = await fetch("/auth/google/oauth", opts)
    const { status, token, history } = await res.json()
    setToken(token)

    return { status, token, history }
  }

  async function getHistory() {
    const req = new Request("/auth/history", { method: "GET" })
    const res = await fetchWithToken(req)
    const { history } = await res.json()
    return history
  }

  return {
    nativeSignIn,
    nativeCreateAccount,
    getToken,
    setToken,
    getHistory,
    fetchWithToken,
    appleLogin,
    googleLogin,
    guestLogin,
  }
}

const existingToken = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
export const authClient = AuthClient(existingToken)
export const client = new DefaultAssessmentService("", authClient.fetchWithToken)
export const checkoutClient = new DefaultCheckoutService("", authClient.fetchWithToken)

//export const authClient = new DefaultAuthService('', fetchWithAuth)
