
import "../css/WarningModal.css"


// Warning modal is displayed when the user clicks on the browser back button.
export const WarningModal = ({ handleLeave, handleContinue }) => {
  return (
    <main id="warning-modal"> 
      <section className="warning-modal-section">
        <div className="close-button-container">
          <i className="close-button-icon" onClick={handleContinue} role="button" aria-label="Close modal" />
        </div>
        <div className="warning-modal-title">Exit Checkout</div>
        <p className="warning-modal-text">
          You are about to leave checkout. If you leave, your information may not be saved.
        </p>
        <div className="warning-modal-buttons">
          <button
            className="button"
            aria-label="Leave page"
            onClick={handleLeave}
          >
            Yes, Leave
          </button>
          <button
            className="button"
            aria-label="Continue with checkout"
            onClick={e => handleContinue(e)}
          >
            No, Continue
          </button>
        </div>
      </section>
    </main>
  )
}
