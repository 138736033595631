import { Link } from 'react-router-dom'

export default function RouterLink(props) {
  const style = {
    color: 'rgb(89,51,245)',
    textDecoration: 'none',
    fontWeight: 'bold'
  }
  return <Link {...props} style={style}>{props.children}</Link>
}
