import { useNavigate } from "react-router-dom"
import "../css/SignUp.css"

const style = {
  layout: {
    layoutMain: {
      background: "white",
    },
    layoutMainMobileInner: {
      width: undefined,
    },
  },
  content: {
    title: {
      fontSize: "27px",
    },
    termsText: {
      fontSize: "16px",
      lineHeight: "20px",
      color: "rgba(99, 99, 99, 1)",
      textDecorationSkipInk: "none",
    },
  },
  divider: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px 0", // Updated margin
  },
  leftContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "0 20px", // Add padding for better spacing
  },
  rightContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
}

const TermsLink = ({ children }) => {
  const style = {
    textDecoration: "underline",
    color: "var( --hyperlink)",
    fontWeight: "inherit",
  }
  return (
    <a href="#" onClick={(e) => e.preventDefault()} style={style}>
      {children}
    </a>
  )
}

const ForgotPasswordLink = ({ children }) => {
  const navigate = useNavigate()
  const toForgotPassword = (e) => {
    e.preventDefault()
    navigate("/forgot-password")
  }
  const linkStyle = {
    cursor: "pointer",
    fontFamily: "Open Sans",
    textDecoration: "underline",
    color: "var(--hyperlink)",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "2rem",
    letterSpacing: "-0.011em",
    textAlign: "left",
  }

  return (
    <div className="forgot-password-link">
      <a onClick={toForgotPassword} href="" style={linkStyle}>
      Forgot Password?
      </a>
    </div>
  )
}

const SignInLink = ({ children }) => {
  const navigate = useNavigate()
  const toSignIn = (e) => {
    e.preventDefault() 
    navigate("/sign-in")
  }
  const style = {
    fontFamily: "Open Sans",
    textDecoration: "underline",
    color: "var( --hyperlink)",
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: "2rem",
    letterSpacing: "-0.011em",
    textAlign: "center",
    cursor: "pointer",
  }
  return (
    <span onClick={toSignIn} style={style}>
      {children}
    </span>
  )
}

const SignUpLink = ({ children }) => {
  const navigate = useNavigate()
  const toCreateAccount = (e) => {
    e.preventDefault()
    navigate("/create-account")
  }
  const linkStyle = {
    cursor: "pointer",
    fontFamily: "Open Sans",
    textDecoration: "underline",
    color: "var(--hyperlink)",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "2rem",
    letterSpacing: "-0.011em",
    textAlign: "left",
  }

  return (
    <div className="sign-up-link">
      <a onClick={toCreateAccount} href="" style={linkStyle}>
      Don’t have an account? Create an account
      </a>
    </div>
  )
}

// Validation functions for email
const isEmailValid = (email) => {
  // const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  const trimmedEmail = email.trim() // Trim white spaces
  return emailPattern.test(trimmedEmail)
}

// Validation check ensuring "Confirm email" matches the original email
const isConfirmEmailValid = (email, confirmEmail) => {
  return email === confirmEmail
}

// Validation functions for password
const isPasswordValid = (password) => {
  // Minimum length of 8 characters and maximum length of 64 characters
  if (password.length < 8 || password.length > 64) {
    return false
  }
  // Check for at least 1 number, 1 uppercase letter, and 1 lowercase letter
  const hasNumber = /[0-9]/.test(password)
  const hasUppercase = /[A-Z]/.test(password)
  const hasLowercase = /[a-z]/.test(password)

  if (!hasNumber || !hasUppercase || !hasLowercase) {
    return false
  }

  // Check if the password contains disallowed keywords
  const disallowedKeywords = ["avanafil", "petros", "ideaevolver", "password", "idea", "evolver"]
  for (const keyword of disallowedKeywords) {
    if (password.toLowerCase().includes(keyword)) {
      return false
    }
  }
  // Password meets all criteria
  return true
}

// Validation check ensuring "Confirm password" matches the original password
const isConfirmPasswordValid = (password, confirmPassword) => {
  return password === confirmPassword
}

export { style, TermsLink, SignInLink, SignUpLink, ForgotPasswordLink, isEmailValid, isConfirmEmailValid, isPasswordValid, isConfirmPasswordValid }
