import React, { useState, useEffect, useRef } from "react"
import Modal from "react-modal"
import { BaseScreen } from "./Assessment"
import { ModalBaseScreen } from "./Assessment"
import moreinfo from "../icn/more-info-2.svg"
import { ReactComponent as InfoIcon } from "../icn/more-info-2.svg"
import LayoutScreen from "./Layout"
import router from "../history"
import { useAudit } from "../audit"
import { AssessmentButton } from "./AssessmentComponents"
import { skipPrompt } from "../prompt"
import { CloseButton } from "../modal"
import useResponsive from "../responsive"

import "../css/MoreInfo.css"

const modalStyle = {
  overlay: {
    backgroundColor: "var(--primary-focus)",
    zIndex: 5,
  },
  content: {
    background: "var(--tertiary)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: "100%",
    maxHeight: "100vh",
    overflowY: "auto",
    padding: 0,
    position: "fixed",
  },
}

const innerStyle = {
  flex: "1 1 0",
  maxHeight: "calc(100% - 100px)", // Adjust this value as needed to leave space for the header and footer
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
}

function Audited({ children }) {
  useAudit({ type: "MOREINFO_DISPLAYED" })
  return <>{children}</>
}

function BaseMoreInfo(props) {
  const { component, children, title = "More Info" } = props
  const [showingInfo, setShowingInfo] = useState(false)

  const closeInfo = () => setShowingInfo(false)
  const showMoreInfo = (e) => {
    e.preventDefault()
    setShowingInfo(true)
  }

  const LinkComponent = component
  return (
    <>
      <LinkComponent showMoreInfo={showMoreInfo} />

      <Modal style={modalStyle} isOpen={showingInfo} onRequestClose={closeInfo}>
        <div
          style={{
            display: "flex",
            flex: "1",
            flexDirection: "column",
            minHeight: "100%",
          }}
        >
          <LayoutScreen>
            <div
              style={{
                ...innerStyle,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <BaseScreen
                title={title}
                showButton={true}
                buttonText={"Back"}
                onSubmit={closeInfo}
              >
                <Audited>{children}</Audited>
              </BaseScreen>
            </div>
          </LayoutScreen>
        </div>
      </Modal>
    </>
  )
}

export function MoreInfoModal({ isOpen, onRequestClose, children }) {
  const [cooldownActive, setCooldownActive] = useState(false)

  const isDesktopActive = useResponsive().desktop
  const wrapperClass = isOpen ? "active" : "deactive"

  useEffect(() => {
    if (isOpen) {
      setCooldownActive(true)
      setTimeout(() => setCooldownActive(false), 1000) // set the cooldown to 1 seconds after the modal is opened
    }
  }, [isOpen])

  const titleContainerStyle = {
    alignItems: "center",
    backgroundColor: "var(--primary)",
    boxShadow: "0px 4px 4px 0px #00000040",
    display: "flex",
    height: isDesktopActive ? "auto" : "64px", // Change the height based on screen (desktop vs mobile)
    paddingTop: "5px",
    paddingBottom: "5px",
    position: "sticky",
    top: 0,
  }

  const titleStyle = {
    color: "var(--white)",
    fontSize: isDesktopActive ? "2.5rem" : "1.5rem",
    margin: "auto",
  }

  const closeButtonStyle = {
    backgroundColor: "var(--secondary)",
    border: "none",
    borderRadius: "50%",
    margin: "25px",
    height: "35px",
    width: "35px",
  }

  const adjustedCloseButtonStyle = cooldownActive
    ? {
        ...closeButtonStyle,
        opacity: 0.5, 
        cursor: "not-allowed", 
      }
    : closeButtonStyle

  const adjustedBackButtonStyle = cooldownActive
    ? {
        opacity: 0.5, 
        cursor: "not-allowed",
      }
    : {}

  // Animation for the modal - sliding in / out
  const handleAnimationModal = () => {
    const modal = document.getElementById("modal")
    modal.style.animationName = "slideout"
    setTimeout(onRequestClose, 1500)
  } 

  return (
    <Modal
      id="modal"
      data-testId="moreInfoModal"
      isOpen={isOpen}
      style={modalStyle}
      onRequestClose={handleAnimationModal}
      className={`wrapper-animation-${wrapperClass} more-info-container`}
    >
      {/* Title Div */}
      <div style={titleContainerStyle}>
        <h1 style={titleStyle}>More Information</h1>
        {/* Close button to the right */}
        <button
          style={adjustedCloseButtonStyle}
          disabled={cooldownActive}
          onClick={!cooldownActive ? handleAnimationModal : null}
        >
          <CloseButton />
        </button>
      </div>
      <div>
        <ModalBaseScreen
          showButton={true}
          buttonText={"Back"}
          buttonStyle={adjustedBackButtonStyle}
          disabled={cooldownActive}
          onSubmit={cooldownActive ? null : handleAnimationModal}
        >
          <Audited>{children}</Audited>
        </ModalBaseScreen>
      </div>
    </Modal>
  )
}

export function BaseModal({ children, style, ...rest }) {
  return (
    <Modal style={{ ...modalStyle, ...style }} {...rest}>
      {children}
    </Modal>
  )
}

export function FullScreenModal({
  isOpen,
  onRequestClose,
  title,
  showButton,
  buttonText,
  onSubmit,
  children,
  showBackButton,
  onBack,
  screenStyle,
}) {
  return (
    <Modal style={modalStyle} isOpen={isOpen} onRequestClose={onRequestClose}>
      <div
        data-testid="fullScreenModal"
        style={{
          display: "flex",
          flex: "1",
          flexDirection: "column",
          minHeight: "100%",
        }}
      >
        <LayoutScreen>
          <div
            style={{ ...innerStyle, display: "flex", flexDirection: "column" }}
          >
            <BaseScreen
              title={title}
              showButton={showButton}
              buttonText={buttonText}
              onSubmit={onSubmit}
              onBack={onBack}
              showBackButton={showBackButton}
              style={screenStyle}
            >
              {children}
            </BaseScreen>
          </div>
        </LayoutScreen>
      </div>
    </Modal>
  )
}

export function MoreInfoIcon({ children }) {
  return (
    <BaseMoreInfo
      component={({ showMoreInfo }) => {
        return (
          <a href="#" onClick={showMoreInfo} style={{ marginLeft: "4px" }}>
            <span className="sr-only">More info</span>
            <InfoIcon />
          </a>
        )
      }}
    >
      {children}
    </BaseMoreInfo>
  )
}

function MoreInfoIconPlus({ style, ...props }) {
  const defaultStyle = {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0",
    fontSize: "18px",
    lineHeight: "18px",
    height: 24,
    borderRadius: "4px",
    verticalAlign: "bottom",
  }

  const defaultOnClick = (e) => e.preventDefault()
  return (
    <a
      href="#"
      style={{ ...defaultStyle, ...style }}
      onClick={defaultOnClick}
      {...props}
    >
      <span>
        <img height="20" width="20" src={moreinfo} alt="" />
      </span>
      <span
        style={{
          marginLeft: 6,
          color: "var(--hyperlink)",
          textDecoration: "underline",
        }}
      >
        {" "}
        More Info{" "}
      </span>
    </a>
  )
}

export function MoreInfoIconPlusComponent({ children }) {
  return (
    <BaseMoreInfo
      component={({ showMoreInfo }) => {
        return (
          <MoreInfoIconPlus onClick={showMoreInfo} style={{ margin: 0 }} />
        )
      }}
    >
      {children}
    </BaseMoreInfo>
  )
}

function Tooltip({ Component }) {
  const [hover, setHover] = useState(true)
  const [tooltip, setTooltip] = useState({})
  const overlayNode = useRef(null)
  const ref = useRef(null)

  useEffect(() => {
    setTooltip(ref.current.getBoundingClientRect())
    setHover(true)
  }, [])

  return (
    <>
      <div
        ref={ref}
        style={{
          display: "inline-block",
        }}
      >
        <Component />
      </div>
      <Modal
        isOpen={hover}
        onRequestClose={() => {
          setHover(false)
        }}
        overlayRef={(node) => {
          overlayNode.current = node
        }}
        onAfterOpen={() => {
          overlayNode.current.style.backgroundColor = "rgba(0,0,0,0.75)"
        }}
        style={{
          content: {
            left: tooltip.left - 20,
            top: tooltip.top + ((tooltip.height + 1) | 0),
            maxWidth: 450,
            background: "none",
            outline: "none",
            border: "none",
            padding: 0,
            margin: 0,
            overflow: "visible",
          },
          overlay: {
            backgroundColor: "rgba(0,0,0,0.1)",
            transition: "background-color .4s ease-out",
          },
        }}
      >
        <div
          onClick={(e) => {
            setHover(false)
          }}
          style={{
            position: "fixed",
            left: tooltip.left,
            top: tooltip.top,
            borderRadius: "4px",
            background: "white",
          }}
        >
          <Component />
        </div>
        <div
          style={{
            marginLeft: (tooltip.width * 0.66) | 0,
            marginTop: 2,
            width: 0,
            height: 0,
            borderLeft: "16px solid transparent",
            borderRight: "16px solid transparent",
            borderBottom: "16px solid white",
          }}
        ></div>
        <div
          style={{
            background: "white",
            //border: "1px solid rgb(204, 204, 204)",
            borderRadius: "4px",
            padding: "30px",
          }}
        >
          <span>
            On some screens you may see an information button (like this:{" "}
          </span>
          <MoreInfoIconPlus style={{ padding: 0, margin: 0 }} />
          <span>
            ). When you see this you can click this to find out additional
            information regarding the topic on the page you are viewing.{" "}
          </span>
          <div style={{ marginTop: "20px" }}>
            <AssessmentButton onClick={() => setHover(false)}>
              Close
            </AssessmentButton>
          </div>
        </div>
      </Modal>
    </>
  )
}

export function TitleInfo({ left, right, children, styleLink }) {
  const [showingInfo, setShowingInfo] = useState(false)

  let title = right ? `${left} ${right}` : left

  // Adding default styling for the link and title
  const defaultStyleLink = {
    marginTop: "16px", 
    marginBottom: "32px" ,
  }

  const defaultTitleLink = {
    marginTop: "4px", 
    marginBottom: "4px" ,
  }

  const stylesLink = styleLink ? styleLink : defaultStyleLink

  return (
    <div style={{}}>
      { title && (
        <h1
          className="assessment-title primary"
          style={defaultTitleLink}
        >
          <span>{title}</span>
        </h1>
      )}
      <div style={stylesLink}>
        <MoreInfoIconPlus
          onClick={(e) => {
            e.preventDefault()
            setShowingInfo(true)
          }}
        />
        <MoreInfoModal
          isOpen={showingInfo}
          onRequestClose={() => setShowingInfo(false)}
        >
          {children}
        </MoreInfoModal>
      </div>
    </div>
  )
}

export function ReturnButton() {
  const [showing, setShowing] = useState(false)

  const screenStyle = {
    buttonContainer: {
      paddingTop: 32,
    },
  }

  return (
    <>
      <AssessmentButton
        data-testid="return-button"
        onClick={() => setShowing(true)}
        variant="primary outline thin"
        style={{ whiteSpace: "nowrap" }}
      >
        Return Later
      </AssessmentButton>
      <FullScreenModal
        isOpen={showing}
        title="Return to this site after you get your blood pressure measured"
        showButton={true}
        showBackButton={true}
        buttonText="Finish"
        onSubmit={() => {
          skipPrompt()
          router.push("/")
        }}
        onBack={() => setShowing(false)}
        screenStyle={screenStyle}
      >
        <p>
          Since you do not have blood pressure numbers taken within the last 3
          months, you can return later to enter them and complete your health
          survey.
        </p>
        <BloodPressureInfo style={{ marginTop: 60 }} />
      </FullScreenModal>
    </>
  )
}

export function TitleInfoTooltip({ left, right, children }) {
  const [showingInfo, setShowingInfo] = useState(false)

  let title = right ? `${left} ${right}` : left

  return (
    <div>
      <h1
        className="primary"
        style={{ lineHeight: "38px", marginTop: "4px", marginBottom: "4px" }}
      >
        <span>{title}</span>
      </h1>
      <div style={{ margin: "0 0 16px 26px" }}>
        <Tooltip
          Component={() => (
            <>
              <MoreInfoIconPlus
                onClick={(e) => {
                  e.preventDefault()
                  setShowingInfo(true)
                }}
              />
              <MoreInfoModal
                isOpen={showingInfo}
                onRequestClose={() => setShowingInfo(false)}
              >
                {children}
              </MoreInfoModal>
            </>
          )}
        />
      </div>
    </div>
  )
}

export function BloodPressureInfo({ style }) {
  style = {
    width: "min(100%, 374px)",
    padding: "14px 21px",
    borderRadius: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
    textAlign: "left",
    height: "auto",
    gap: "16px",
    ...style,
  }

  return (
    <BaseMoreInfo
      title="You need to enter a current (taken in past 3 months) blood pressure"
      component={({ showMoreInfo }) => (
        <button className="btn tertiary" style={style} onClick={showMoreInfo}>
          <b>How to check your blood pressure</b>
          <svg
            width="15"
            height="23"
            viewBox="0 0 15 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.42969 2L12.9353 10.8214L2.42969 21"
              stroke="white"
              strokeWidth="3"
              strokeMiterlimit="16"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      )}
    >
      <div>
        <p style={{ marginBottom: "12px" }}>
          There are fast and easy ways to get your blood pressure measured:
        </p>
        <ul>
          <li>check with your doctor</li>
          <li>visit the local fire station</li>
          <li>check with your school nurse</li>
          <li>
            get your blood pressure from your health portal if you have one
          </li>
          <li>use a home blood pressure device if you have one</li>
          <li>use a blood pressure machine located in most pharmacy stores</li>
        </ul>
      </div>
    </BaseMoreInfo>
  )
}
