import React, { useEffect, useState, createElement, useRef } from "react"
import { useSelector } from "react-redux"
import { googleAuth, appleAuth } from "../components/Auth"
import "../css/AssessmentTitle.css"
import "../css/Banner.css"
import "../css/RadioList.css"
import "../css/Checkbox.css"
import "../css/SelectOption.css"
import "../css/SelectList.css"
import "../css/PrivacyNotice.css"
import "../css/DateInput.css"
import "../css/YesNoButtons.css"
import "../css/Typography.css"
import Button from "./Button"
import Textfield from "./Textfield"
import icons from "../icn/index"
import images from "../img/index"
import { MoreInfoIcon, MoreInfoIconPlusComponent, TitleInfo, TitleInfoTooltip, BloodPressureInfo } from "./MoreInfo"
import { Auth } from "../components/Auth"
import ModalConfirm from "./ConfirmModals"
import PrivacyRest from "./PrivacyRest"
import useResponsive from "../responsive"
import { Screen, KickoutScreen } from "./Assessment"
import { Link } from "react-router-dom"
import GenericModal from "./Modal"

export function AssessmentButton({ onClick, variant = "primary", children, ...rest }) {
  return (
    <Button onClick={onClick} variant={variant} {...rest}>
      {children}
    </Button>
  )
}

export function AssessmentRadio({ name, value, defaultChecked, onChange }) {
  return (
    <input
      name={name}
      value={value}
      defaultChecked={defaultChecked}
      type="radio"
      style={{ width: "20px", height: "20px" }}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

export function AssessmentScreenTitle({ text, variant = "primary", style }) {
  return <h1 className={`assessment-title ${variant}`} style={{ ...style }} dangerouslySetInnerHTML={{ __html: text }}></h1>
}

export function CheckoutScreenTitle({ text, variant = "primary", style }) {
  return <h1 className={`checkout-title ${variant}`} style={{ ...style }} dangerouslySetInnerHTML={{ __html: text }}></h1>
}

export function ComponentColumn({ children, style }) {
  return (
    <div className="column" style={{ display: "flex", flexDirection: "column", ...style }}>
      {children}
    </div>
  )
}

export function ComponentImage({ img }) {
  return img && images[img] && <img alt={img} src={images[img]} />
}

export function Image({ src }) {
  return <img src={images[src]} alt="" style={{ width: "100%", height: "auto" }} />
}

export function Div({ children, style }) {
  return <div style={style}>{children}</div>
}

export function ComponentSpacer({ style = {} }) {
  return (
    <hr
      style={{
        border: "none",
        width: "calc(100% - 8px)",
        height: "2px",
        backgroundColor: "var(--black)",
        margin: "auto 4px",
        ...style,
      }}
    />
  )
}

export function ComponentLink({ text, href, style = {} }) {
  console.log(style)
  return (
    <a href={href} style={style}>
      {text}
    </a>
  )
}

export function ComponentList({ ordered = false, style = {}, options, title }) {
  const titleStyle = {
    marginBottom: "12px",
    ...style.title,
  }
  return ordered ? (
    <div style={style.root}>
      {title && <p style={titleStyle}>{title}</p>}
      <ol style={style.list || {}}>
        {options.map((opt) => (
          <li style={style.item || {}} key={opt} dangerouslySetInnerHTML={{ __html: opt }}></li>
        ))}
      </ol>
    </div>
  ) : (
    <div style={style.root}>
      {title && <p style={titleStyle}>{title}</p>}
      <ul style={style.list || {}}>
        {options.map((opt) => (
          <li style={style.item || {}} key={opt} dangerouslySetInnerHTML={{ __html: opt }} className="list-item"></li>
        ))}
      </ul>
    </div>
  )
}

export function ComponentRow({ justify, children, style }) {
  var j = "flex-start"
  if (justify === "center") {
    j = "center"
  } else if (justify === "end") {
    j = "flex-end"
  }
  return <div style={{ display: "flex", justifyContent: j, ...style }}>{children}</div>
}

export function Banner({ text, style }) {
  return <p className="assessment-banner general-banner" style={style} dangerouslySetInnerHTML={{ __html: text }}></p>
}

export function BannerWithOptions({ text, text2, style, list, modal, children }) {
  const styleLink = {
    marginTop: "5px",
  }

  return (
    <section className="assessment-BannerWithOptions general-banner" style={style}>
      <span>{text}</span>
      <br />
      {text2 && <span>{text2}</span>}

      {modal && children && <TitleInfo children={children} styleLink={styleLink} />}

      {list && (
        <div className="assessment-bannerList">
          <ul>
            {list.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      )}
    </section>
  )
}

export function Paragraph({ text, style, icon }) {
  return <p className="assessment-paragraph" style={style} dangerouslySetInnerHTML={{ __html: text }}></p>
}

export function PrivacyNotice({ onBack, onSubmit }) {
  const [isNoticeOpen, setIsNoticeOpen] = useState(false)

  const handleClick = (e) => {
    e.preventDefault()
    setIsNoticeOpen(true)
  }

  return (
    <section className="privacy-notice">
      <Link className="privacy-link" onClick={handleClick}>
        Click here to read the full privacy notice
      </Link>
      {isNoticeOpen && (
        <GenericModal
          isOpen={isNoticeOpen}
          buttonText="Back"
          onRequestClose={() => setIsNoticeOpen(false)}
          stickyButton={true}
          title="Full Privacy Notice"
        >
          <PrivacyRest />
        </GenericModal>
      )}

      <div className="buttons">
        <AssessmentButton onClick={() => onSubmit({ AcceptPrivacyNotice: new Date().toISOString() })} variant="secondary">
          I Accept
        </AssessmentButton>
      </div>
    </section>
  )
}

export function ComponentTextfieldQuestion({ id, question, value, icon, variant, placeholder, onEvent }) {
  const labelStyle = {
    display: "block",
    marginBottom: "16px",
  }

  return (
    <>
      <label htmlFor={id} style={labelStyle}>
        {question}
      </label>
      <Textfield
        id={id}
        value={value || ""}
        placeholder={placeholder || ""}
        variant={variant}
        icon={icon && icons[icon] ? icons[icon] : null}
        onChange={(e) =>
          onEvent({
            componentId: id,
            type: "UPDATED_ANSWER",
            newValue: e.target.value,
          })
        }
        onFocus={() => onEvent({ componentId: id, type: "INPUT_SELECTED" })}
        onBlur={() => onEvent({ componentId: id, type: "INPUT_DESELECTED" })}
      />
    </>
  )
}

export function RadioSelect({ id, question, options, value, onEvent }) {
  const style = {
    display: "flex",
    gap: "20px",
    justifyContent: "space-between",
    padding: "20px",
    border: "1px solid lightgrey",
    borderRadius: "5px",
  }
  return (
    <div style={style}>
      <span>{question}</span>
      <div style={{ display: "flex", flexShrink: 0 }}>
        {options.map((opt) => (
          <div
            key={opt}
            style={{
              display: "flex",
              marginLeft: "4px",
              border: "1px solid rgba(39, 39, 39, 0.64)", // Add dark outline
              borderRadius: "5px", // Add rounded corners
              backgroundColor: value === opt ? "rgb(7, 21, 59)" : "transparent", // Add navy background when selected
            }}
          >
            <AssessmentRadio
              name={id}
              value={opt}
              defaultChecked={value === opt}
              onChange={(v) =>
                onEvent({
                  componentId: id,
                  type: "UPDATED_ANSWER",
                  newValue: v,
                })
              }
            />
            <span> {opt} </span>
          </div>
        ))}
      </div>
    </div>
  )
}

function range(a, b) {
  const ret = []
  for (var i = a; i < b; i++) {
    ret.push(i)
  }
  return ret
}

export function OptionSelect({ id, question, placeholder, icon, variant, style = {}, options, value, onChange }) {
  const placeholderOption = {
    disabled: true,
    hidden: true,
    text: placeholder,
    value: "placeholder",
  }
  options = [placeholderOption, ...options]
  return (
    <div className={`select ${variant}`} style={{ ...style.list }}>
      {question && <p className="question">{question}</p>}
      <label>
        <span className="sr-only">Choose your height</span>
        {icon && icons[icon] && <img className="icon" src={icons[icon]} alt="" />}
        <select
          name={id}
          style={{ paddingLeft: icon ? "56px" : "16px" }}
          value={value || "placeholder"}
          onChange={(e) => onChange(e.target.value)}
        >
          {options.map((opt) => (
            <option key={opt.value} value={opt.value} disabled={opt.disabled} hidden={opt.hidden}>
              {opt.text}
            </option>
          ))}
        </select>

        <img className="chevron" src={icons["chevron-down"]} alt={`open ${id} menu`} />
      </label>
    </div>
  )
}

export function HeightSelect(props) {
  const optionRange = range(58, 58 + 24).map((value) => ({
    value,
    text: `${(value / 12) | 0} feet, ${value % 12} inches`,
  }))

  const { id, onEvent } = props
  const handleChange = (value) => {
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: value })
  }
  return <OptionSelect options={optionRange} onChange={handleChange} {...props} />
}

export function Label({ htmlFor, label, style = {} }) {
  return (
    <label htmlFor={htmlFor} style={style}>
      {label}
    </label>
  )
}

export function NumericInput({
  id,
  question,
  infoLink,
  placeholder,
  icon,
  variant,
  style = {},
  units,
  value,
  err,
  hideErrs,
  onEvent,
  maxLength,
  nextFocus,
  hideLabel = false,
}) {
  const { desktop } = useResponsive()

  const inputStyle = { ...style.input }
  const unitsStyle = {}
  if (err) {
    inputStyle.borderColor = "#ED0000CC"
    inputStyle.backgroundColor = "#FFE9E9"

    unitsStyle.borderLeft = "1px solid #ED0000CC"
  }

  const onChange = (e) => {
    let v = e.target.value.trim()

    if (value === undefined && v === "") {
      return
    }

    //const val = e.target.value.replace(/\D/g, "")
    if (nextFocus && v.length === maxLength) {
      const el = document.getElementById(nextFocus)
      el.focus()
    }
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: v })
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        ...(style.base || {}),
      }}
    >
      {!hideLabel && (
        <label
          htmlFor={id}
          style={{ marginBottom: "12px", ...style.label }}
          dangerouslySetInnerHTML={{ __html: question }}
        ></label>
      )}
      {infoLink && (
        <a href={infoLink.href} style={style.infoLink || {}}>
          {infoLink.text}
        </a>
      )}
      <div style={{ display: "flex", width: "100%", maxWidth: "500px" }}>
        <Textfield
          id={id}
          value={value || ""}
          variant={variant}
          inputMode="numeric"
          pattern="[0-9]*"
          style={{ input: inputStyle, units: unitsStyle }}
          placeholder={placeholder || ""}
          units={units}
          icon={icon && icons[icon] ? icons[icon] : null}
          onChange={onChange}
          onFocus={() => hideErrs()}
          maxLength={maxLength}
        />
      </div>
      {err && (
        <span
          style={{
            color: "#ED0000",
            marginTop: "16px",
            ...(desktop ? { whiteSpace: "nowrap" } : {}),
          }}
        >
          {err}
        </span>
      )}
    </div>
  )
}

export function DateInput2({ id, question, placeholder, value, err, hideErrs, onEvent, hideLabel = false }) {
  const dayRef = useRef(null)
  const monthRef = useRef(null)
  const yearRef = useRef(null)

  const [month, setMonth] = useState("")
  const [day, setDay] = useState("")
  const [year, setYear] = useState("")
  const [navBack, setNavBack] = useState(false)
  const [monthError, setMonthError] = useState(false)
  const [dayError, setDayError] = useState(false)
  const [yearError, setYearError] = useState(false)

  const { desktop } = useResponsive()

  function isValidDate(d, m, y) {
    d = parseInt(d, 10)
    m = parseInt(m, 10) - 1
    y = parseInt(y, 10)

    if (isNaN(d) || isNaN(m) || isNaN(y)) {
      console.log("One of the inputs is not a number.")
      return false
    }

    var enteredDate = new Date(y, m, d)

    // Check if the entered date itself is valid
    if (!(enteredDate && enteredDate.getDate() === d && enteredDate.getMonth() === m && enteredDate.getFullYear() === y)) {
      return false
    }

    if (y < 1900) {
      return false
    }

    // Get the current date
    var currentDate = new Date()

    // Reset time portions to zero to compare just the date parts
    enteredDate.setHours(0, 0, 0, 0)
    currentDate.setHours(0, 0, 0, 0)

    // Check if the entered date is not current or future date
    return enteredDate < currentDate
  }

  const handleKeyUp = (e) => {
    // Check if the key is an arrow key and return immediately if it is
    if (e.keyCode === 37 || e.keyCode === 39) {
      return
    }

    // Check if the backspace key was pressed and handle it
    if (e.keyCode === 8) {
      handleBackspace(e)
      return
    } else {
      setNavBack(false)
    }

    // Check if the max length of the input field was reached and handle it
    if (e.target.value.length >= e.target.maxLength) {
      handleMaxLength(e)
      return
    }
  }

  const handleBackspace = (e) => {
    if (e.target.value === "" && !navBack) {
      setNavBack(true)
      return
    }

    if (e.target.value === "" && navBack) {
      setNavBack(false)
      switch (e.target.name) {
        case "day":
          monthRef.current.focus()
          setMonth(month.slice(0, -1))
          break
        case "year":
          dayRef.current.focus()
          setDay(day.slice(0, -1))
          break
        default:
          break
      }
    }
  }

  const handleMaxLength = (e) => {
    if (e.keyCode === 9 || e.keyCode === 16) return

    switch (e.target.name) {
      case "month":
        dayRef.current.focus()
        break
      case "day":
        yearRef.current.focus()
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (value) {
      const [y, m, d] = value.split("-")

      setMonth(m)
      setDay(d)
      setYear(y)
    }
  }, [value])

  useEffect(() => {
    if (year.length === 4 && day.length === 2 && month.length === 2 && isValidDate(day, month, year)) {
      onEvent({
        componentId: id,
        type: "UPDATED_ANSWER",
        newValue: `${year}-${month}-${day}`,
      })
    } else {
      onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: undefined }) // Pass undefined for invalid/incomplete dates
    }
  }, [month, day, year])

  return (
    <div>
      <div className="date-input-group" onKeyUp={(e) => handleKeyUp(e)}>
        <input
          type="number"
          name="month"
          ref={monthRef}
          value={month}
          placeholder="MM"
          min="1"
          max="12"
          maxLength="2"
          onChange={(e) => {
            const newMonth = e.target.value
            if (newMonth.length <= 2) {
              setMonth(e.target.value)
            }

            if (parseInt(newMonth) > 12) {
              setMonthError("That month isn't valid. Check the month and day aren't reversed.")
            } else {
              setMonthError("")
            }
          }}
          onBlur={(e) => {
            if (e.target.value.length !== 2) {
              setMonthError("Enter the month as a 2-digit number.")
            }
          }}
        />
        /
        <input
          type="number"
          name="day"
          ref={dayRef}
          value={day}
          placeholder="DD"
          maxLength="2"
          onChange={(e) => {
            const newDay = e.target.value
            if (e.target.value.length <= 2) {
              setDay(e.target.value)
            }

            if (parseInt(newDay) > 31) {
              setDayError("Day must be between 1 and 31.")
            } else {
              setDayError("")
            }
          }}
          onBlur={(e) => {
            if (e.target.value.length !== 2) {
              setDayError("Enter the day as a 2-digit number.")
            }
          }}
        />
        /
        <input
          type="number"
          name="year"
          ref={yearRef}
          value={year}
          placeholder="YYYY"
          min="1901"
          max="2023"
          maxLength="4"
          onBlur={(e) => {
            if (e.target.value.length < 4) {
              setYearError("Enter all 4 digits for year.")
            } else {
              setYearError("")
            }
            if (parseInt(e.target.value) > 2023) {
              setYearError("Please enter a valid birth year.")
            } else if (parseInt(e.target.value) < 1900) {
              setYearError("Please enter a valid birth year")
            } else {
              setYearError("")
            }
          }}
          onChange={(e) => {
            if (e.target.value.length <= 4) {
              setYear(e.target.value)
            }
            if (e.target.value.length === 4) {
              yearRef.current.blur()
            }
          }}
        />
      </div>
      <div className="date-error-group">
        {monthError && <span>{monthError}</span>}
        {dayError && <span>{dayError}</span>}
        {yearError && <span>{yearError}</span>}
        {err && (
          <span
            style={{
              color: "#ED0000",
              marginTop: "16px",
              ...(desktop ? { whiteSpace: "nowrap" } : {}),
            }}
          >
            {err}
          </span>
        )}
      </div>
    </div>
  )
}

export function DateInput({
  id,
  question,
  placeholder,
  icon,
  variant,
  style = {},
  value,
  err,
  hideErrs,
  onEvent,
  hideLabel = false,
}) {
  const { desktop } = useResponsive()

  const onChange = (e) => {
    let v = e.target.value
    if (value === undefined && v === "") {
      return
    }

    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: v })
  }
  return (
    <div
      style={{
        ...(style.base || {}),
      }}
    >
      {!hideLabel && (
        <label
          htmlFor={id}
          style={{ marginBottom: "12px", ...style.label }}
          dangerouslySetInnerHTML={{ __html: question }}
        ></label>
      )}
      <div style={{ display: "flex", width: "100%", maxWidth: "500px" }}>
        <input
          className={`date-input ${variant}`}
          type="date"
          id={id}
          data-testid="testDateInput"
          value={value || ""}
          min="1900-01-01"
          max="2025-12-31"
          onChange={onChange}
          onFocus={() => hideErrs()}
        />
      </div>
      {err && (
        <span
          style={{
            color: "#ED0000",
            marginTop: "16px",
            ...(desktop ? { whiteSpace: "nowrap" } : {}),
          }}
        >
          {err}
        </span>
      )}
    </div>
  )
}

// Assessment component for signing in during assessment
export function SignUpComponent({ onSubmit }) {
  const authed = useSelector((state) => state.auth.authed)
  const [signUpModal, setSignUpModal] = useState({ show: true })

  return (
    <>
      <SignUp
        isOpen={signUpModal.show}
        onApple={async () => {
          await appleAuth("signup", true)
          onSubmit()
        }}
        onGoogle={async () => {
          await googleAuth("signup", true)
          onSubmit()
        }}
        onGuest={() => onSubmit()}
      />
    </>
  )
}

export function SignUp({ onGoogle, onApple, onGuest }) {
  const { desktop } = useResponsive()

  const style = {
    layout: {
      navbar: {
        header: {
          background: desktop ? "rgba(252, 252, 252, 1)" : "white",
        },
      },
      layoutMain: {
        background: "white",
      },
      layoutMainMobileInner: {
        width: undefined,
      },
    },
    content: {
      title: {
        fontSize: "27px",
      },
      termsText: {
        fontSize: "16px",
        lineHeight: "20px",
        color: "rgba(99, 99, 99, 1)",
        textDecorationSkipInk: "none",
      },
    },
  }

  const TermsLink = ({ children }) => {
    const style = {
      textDecoration: "underline",
      color: "inherit",
      fontWeight: "inherit",
    }
    return (
      <a href="#" onClick={(e) => e.preventDefault()} style={style}>
        {children}
      </a>
    )
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "392px",
            margin: "0 28px 32px 28px",
          }}
        >
          <div style={{ margin: desktop ? "0 28px" : undefined }}>
            <h1
              className="primary"
              style={{
                ...style.content.title,
                padding: 0,
                textAlign: "center",
                marginBottom: "40px",
              }}
            >
              Don't lose your progress, create an account.
            </h1>
            <div style={{ margin: "0 0 114px 0" }}>
              <Auth
                onSelect={(name, _) => {
                  if (name === "guest") {
                    onGuest()
                  } else if (name === "google") {
                    onGoogle()
                  } else if (name === "apple") {
                    onApple()
                  }
                }}
              />
            </div>
          </div>
          <p style={{ ...style.content.termsText, margin: 0 }}>
            By using your Google or Apple ID you accept our <TermsLink>terms of use</TermsLink> and{" "}
            <TermsLink>privacy policy</TermsLink>.
            <br />
            <br />
            <br />
            If you continue as a guest, your answers will not be saved when you quit and you will need to restart.
          </p>
        </div>
      </div>
    </>
  )
}

export function RadioList({ id, options, value, onSelect }) {
  const handleEvent = (e) => {
    if (e.type === "UPDATED_ANSWER") {
      onSelect(e.newValue)
    }
  }
  return <RadioSelectList id={id} options={options} value={value} variant="primary" onEvent={handleEvent} />
}

export function RadioSelectList({ id, question, infoLink, style = {}, options, value, variant, onEvent }) {
  return (
    <div className={`radio-list ${variant}`} style={{ ...(style.list || {}) }}>
      {question && <p style={style.question || {}}>{question}</p>}
      {infoLink && <a href={infoLink.href}>{infoLink.text}</a>}

      <div className="radio-group" role="radiogroup">
        {options.map((opt) => (
          <label key={opt.value} className="radio-item" style={style.item || {}}>
            {opt.icon && icons[opt.icon] && <img src={icons[opt.icon]} alt="" />}
            <div>
              {" "}
              <div className="radio-container">
                <input
                  type="radio"
                  name={id}
                  value={opt.value}
                  checked={value === opt.value}
                  aria-checked={value === opt.value}
                  onChange={(e) => {
                    onEvent({
                      componentId: id,
                      type: "UPDATED_ANSWER",
                      newValue: e.target.value,
                    })
                  }}
                />
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path d="M0 16C0 7.16344 7.16344 0 16 0V0C24.8366 0 32 7.16344 32 16V16C32 24.8366 24.8366 32 16 32V32C7.16344 32 0 24.8366 0 16V16Z" />
                  <path d="M11 17L14 20L20.5 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <div style={{ flex: "1" }}>
                <h2>{opt.title}</h2>
                {opt.text && <p>{opt.text}</p>}
              </div>
              {opt.rightText && <span className="right-text">{opt.rightText}</span>}
            </div>
          </label>
        ))}
      </div>
    </div>
  )
}

// TODO maybe just embed this in CheckboxList?
export function Checkbox({
  title,
  text,
  value,
  icon,
  className,
  variant,
  onCheck,
  checked,
  moreInfo,
  style = {},
  reversed = false,
}) {
  let m
  if (moreInfo) {
    m = createComponent(moreInfo)
  }

  if (reversed) {
    style.labelContainer = { flexDirection: "row-reverse" }
  }

  return (
    <label className={`checkbox ${variant} ${className}`} style={{ padding: icon ? "0" : "0px", ...style.label }}>
      {icon && icons[icon] && <img src={icons[icon]} alt="" />}
      <div className="label-container" style={style.labelContainer}>
        <div className="checkbox-container" style={style.checkboxContainer}>
          <input value={value} type="checkbox" checked={checked} onChange={(e) => onCheck(e.target.checked)} />
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            stroke="var(--primary)"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
          >
            <path d="M9 1.5H23V0.5H9V1.5ZM30.5 9V23H31.5V9H30.5ZM23 30.5H9V31.5H23V30.5ZM1.5 23V9H0.5V23H1.5ZM9 30.5C4.85786 30.5 1.5 27.1421 1.5 23H0.5C0.5 27.6944 4.30558 31.5 9 31.5V30.5ZM30.5 23C30.5 27.1421 27.1421 30.5 23 30.5V31.5C27.6944 31.5 31.5 27.6944 31.5 23H30.5ZM23 1.5C27.1421 1.5 30.5 4.85786 30.5 9H31.5C31.5 4.30558 27.6944 0.5 23 0.5V1.5ZM9 0.5C4.30558 0.5 0.5 4.30558 0.5 9H1.5C1.5 4.85786 4.85786 1.5 9 1.5V0.5Z" />
            <path d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z" />
            <path d="M11 17L14 20L20.5 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className="text-container">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "4px",
              alignItems: "center",
            }}
          >
            <p className="title">{title}</p>
            {moreInfo && m}
          </div>
          {/* Commented out the following unused lines to perfectly center checkbox label */}
          {/* <p className="text" style={style.text}>
            {text}
          </p> */}
        </div>
      </div>
    </label>
  )
}

export function CheckboxList({ id, question, options, none, onEvent, value }) {
  const isNoneChecked = value !== undefined && value.length === 0

  const onCheckRegular = (opt, checked) => {
    let newValue
    if (checked) {
      const currentValues = value === undefined ? [] : value
      newValue = [...currentValues, opt.value]
    } else {
      newValue = value.filter((v) => v !== opt.value)
      if (newValue.length === 0) {
        newValue = undefined
      }
    }
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue })
  }
  const children = options.map((opt, i) => (
    <Checkbox
      {...opt}
      key={i}
      value={opt.value}
      onCheck={(checked) => onCheckRegular(opt, checked)}
      checked={value !== undefined && value.includes(opt.value)}
    />
  ))

  if (none) {
    const onCheckNone = (checked) => {
      let newValue
      if (checked) {
        newValue = []
      } else {
        newValue = undefined
      }
      onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue })
    }
    children.push(<Checkbox {...none} key={options.length} onCheck={onCheckNone} checked={isNoneChecked} />)
  }

  const style = {
    border: "1px solid rgba(39, 39, 39, 0.16)",
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
  }
  return <div style={style}>{children}</div>
}

export function SelectList({ id, question, options, value, onSubmit }) {
  return (
    <div className="select-list">
      <p>{question}</p>
      <div className="select-list-options">
        {options.map((opt) => (
          <div
            key={opt.value}
            className="select-list-option"
            onClick={() => onSubmit({ [id]: opt.value })}
            role="button"
            tabIndex={0}
          >
            {opt.icon && icons[opt.icon] ? <img src={icons[opt.icon]} alt="" /> : null}
            <div>
              <div style={{ flex: "1", marginRight: "17px" }}>
                <h2>{opt.title}</h2>
                <p>{opt.text}</p>
              </div>
              <svg width="14" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7.70711 6.29289C8.09763 6.68342 8.09763 7.31658 7.70711 7.70711L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                  fill="#272727"
                  fillOpacity="0.64"
                />
              </svg>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export function MultiChoiceButtons({ id, question, onEvent }) {
  return (
    <div>
      <label>{question}</label>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <AssessmentButton
          onClick={() =>
            onEvent({
              componentId: id,
              type: "UPDATED_ANSWER",
              newValue: "yes",
            })
          }
        >
          Yes
        </AssessmentButton>
        <AssessmentButton onClick={() => onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: "no" })}>
          No
        </AssessmentButton>
      </div>
    </div>
  )
}

export function ComponentYesNoRadioQuestion(props) {
  //{id, question, value, onEvent}
  const style = {
    display: "flex",
    gap: "20px",
    justifyContent: "space-between",
    padding: "20px",
    border: "1px solid lightgrey",
    borderRadius: "5px",
  }
  const options = ["yes", "no"]

  return <RadioSelect {...props} options={options} />
}

export function YesNoButtons({ id, question = "", onEvent, onSubmit, variant = "primary" }) {
  const options = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ]

  const handleClick = (e) => {
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: e.target.value })
    onSubmit({ [id]: e.target.value })
  }
  return (
    <div
      className="yes-no"
      style={{ display: "flex", flexDirection: "column", flexGrow: "1", marginTop: "1rem", width: "100%" }}
    >
      <div style={{ display: "flex", flexGrow: "1", alignItems: "center" }}>{question}</div>
      <div className="yes-no-group">
        {options.map(({ value, label }) => (
          <AssessmentButton value={value} key={label} onClick={(e) => handleClick(e)} variant={variant}>
            {label}
          </AssessmentButton>
        ))}
      </div>
    </div>
  )
}

// function ComponentContinueButton({ onClick }) {
//   return <AssessmentButton onClick={onClick}>Continue</AssessmentButton>
// }

function Span({ text, style }) {
  return <span style={style}> {text} </span>
}

export function TextInput({ onChange, value }) {
  const defaultStyle = {
    outline: "none",
    border: "none",
  }
  return <input type="text" style={defaultStyle} onChange={onChange} value={value} />
}

export function ColorPicker({ id, value, onEvent, options }) {
  const update = (color) => {
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: color })
  }

  const baseStyle = {
    width: 40,
    height: 40,
  }

  const selectedStyle = {
    ...baseStyle,
    border: "4px solid black",
  }

  return (
    <div>
      {options.map((opt) => {
        const style = opt.value === value ? selectedStyle : baseStyle

        return <div style={{ ...style, background: opt.value }} onClick={() => update(opt.value)}></div>
      })}
    </div>
  )
}

export function NitrateImages() {
  const { mobile } = useResponsive()

  const style = {
    container: { display: "flex", alignItems: "center", gap: "16px", margin: "25px" },
    item: { flex: 1 },
    img: { width: "100%", height: "auto" },
  }

  if (mobile) {
    style.container.flexDirection = "column"
  }
  return (
    <div style={style.container}>
      <div style={style.item}>
        <img alt="nitrate 1" src={images.nitrate1} style={style.img} />
      </div>
      <div style={style.item}>
        <img alt="nitrate 2" src={images.nitrate2} style={style.img} />
      </div>
      <div style={style.item}>
        <img alt="nitrate 3" src={images.nitrate3} style={style.img} />
      </div>
    </div>
  )
}

const components = {
  PrivacyNotice,
  Banner,
  BannerWithOptions,
  Button: AssessmentButton,
  Checkbox: Checkbox,
  CheckboxList,
  Div,
  BloodPressureInfo,
  Column: ComponentColumn,
  Image: Image,
  Link: ComponentLink,
  List: ComponentList,
  ModalConfirm,
  MoreInfoIcon,
  MoreInfoIconPlusComponent,
  MultiChoiceButtons: MultiChoiceButtons,
  NumericInput,
  DateInput: DateInput2,
  HeightSelect,
  Paragraph,
  RadioSelect,
  RadioSelectList,
  Row: ComponentRow,
  SelectList: SelectList,
  Spacer: ComponentSpacer,
  Span,
  TextInput,
  TextfieldQuestion: ComponentTextfieldQuestion,
  Title: AssessmentScreenTitle,
  TitleInfo,
  TitleInfoTooltip,
  YesNoButtons: YesNoButtons,
  YesNoRadioQuestion: ComponentYesNoRadioQuestion,
  Label,
  ColorPicker,
  NitrateImages,
  SignUpComponent,
  Screen,
  KickoutScreen,
}

export function registerComponent(name, def) {
  components[name] = def
}

export function registerCustomComponent(name, def) {
  const fn = (props) => {
    return createComponent(def, props)
  }

  fn.displayName = name
  components[name] = fn
}

export const ComponentContext = React.createContext({})
//getValue, onEvent, onSubmit
export function createComponent(componentspec, props = {}) {
  // create read only component

  // don't pass key through the tree, or else we get children w/ dupe keys
  //const { key, ...rest } = props
  const rest = Object.assign({}, props, { key: undefined })

  var renderedchildren = []
  if (componentspec.children) {
    renderedchildren = componentspec.children.map((c) => createComponent(c, rest))
  }

  const component = components[componentspec.type]
  if (component === undefined) {
    console.error("component missing", componentspec.type)
    return null
  }

  const { children, ...specProps } = componentspec
  const mergedProps = {
    ...specProps,
    ...props,
  }

  if (componentspec.id) {
    //FIXME:
    mergedProps.value = props.getValue && props.getValue(componentspec.id)
    mergedProps.key = componentspec.id

    const err = props.screenErrs?.[componentspec.id]
    if (err && props.shouldShowErrs) {
      mergedProps.err = err
    }
  }

  return createElement(component, mergedProps, ...renderedchildren)
}
